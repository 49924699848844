<div class="floating-alert mt-4">
    <div class="alert alert-{{ alertObject.type }}" *ngFor="let alertObject of alerts">
        <div class="flex-grow-1">{{ alertObject.message }}</div>
        <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            (click)="closeAlert(alertObject)"
        ></button>
    </div>
</div>
