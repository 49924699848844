import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { User } from '../../classes/user';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { FirebaseMessagingService } from '../../services/firebase-messaging.service';

/** Write/read data of user's to/from local storage, */
@Injectable({ providedIn: 'root' })
export class AuthService {
  loggedInUser: User;

  constructor(
    private httpService: HttpService,
    private translate: TranslateService,
    private toastMessageService: ToastMessageService,
    private messagingService: FirebaseMessagingService
  ) {}

  login(email, password): Observable<any> {
    return this.httpService.post('/admin/login', { email: email, password: password }).pipe(
      map((response) => {
        this.loggedInUser = this.getUserData(response.api_token);
        this.messagingService.requestPermission();
        return response;
      })
    );
  }

  passwordConfirm(email, password, passwordConfirm, pin): Observable<any> {
    return this.httpService
      .post('/admin/confirm-registration', {
        email: email,
        email_token: pin,
        password: password,
        password_confirmation: passwordConfirm,
      })
      .pipe(
        map((response) => {
          this.loggedInUser = this.getUserData(response.api_token);
          return response;
        })
      );
  }

  logout() {
    localStorage.removeItem('auth-token');

    this.translate.get('LOGOUT.LOGOUT_SUCCESS_MESSAGE').subscribe((res: string) => {
      this.toastMessageService.showSucccess(res);
    });
  }

  setToken(token) {
    localStorage.setItem('auth-token', token);
  }

  getUserData(apiToken = this.getAuthToken()): User {
    const helper = new JwtHelperService();
    return helper.decodeToken(apiToken);
  }

  isLoggedIn() {
    return !!localStorage.getItem('auth-token');
  }

  getAuthToken() {
    return localStorage.getItem('auth-token');
  }

  getUser() {
    return this.loggedInUser;
  }

  loadUser() {
    return of(this.getUserData());
  }

  checkAuth(): Promise<boolean> {
    const token = localStorage.getItem('auth-token');

    return new Promise((resolve, reject) => {
      if (token) {
        this.setToken(token);

        this.loadUser().subscribe(
          (user) => {
            this.loggedInUser = user;
            resolve(true);
          },
          () => {
            this.logout();
            resolve(true);
          }
        );
      } else {
        this.logout();
        resolve(true);
      }
    });
  }
}
