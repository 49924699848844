<div class="ag-table-ng-select">
    <app-selector-dropdown
        [tableLayout]="true"
        [data]="params.dropdownData"
        [defaultSelection]="selected"
        [placeholderText]="params.placeholderText"
        [clearable]="params.clearable"
        (selectedChange)="setSelected($event)"
    >
    </app-selector-dropdown>
</div>
