import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MainConstants } from '../../constant';
import * as moment from 'moment';
import { ReportsService } from './services/reports.service';
import { NgbDateConverter } from './../../util/ngb-date-converter';
import { DeviceService } from '../../services/device.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../toast-message/toast-message.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportsComponent implements OnInit, OnDestroy {
  private dataSubscription: Subscription;
  public reports;
  public products;
  public selectedProduct;
  public fromDate;
  public toDate;
  private updateUserTimeout: any;

  constructor(
    public deviceService: DeviceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public reportService: ReportsService,
    private translate: TranslateService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.dataSubscription = this.activatedRoute.data.subscribe((response) => {
      this.reports = response.reportsData[0];
      this.products = response.reportsData[1];
      this.loadData();
    });
  }

  loadData() {
    this.reportService.getReportsByProduct();

    this.selectedProduct = this.reportService.queryParams.products
      ? this.getSelectedProducts(this.reportService.queryParams.products.split(','))
      : null;
    this.fromDate = this.reportService.queryParams.from
      ? NgbDateConverter.getNgbDate(
          moment(parseInt(this.reportService.queryParams.from * 1000 + '', 10)).format(
            MainConstants.REPORTS.DATE_FORMAT_MOMENT
          )
        )
      : null;
    this.toDate = this.reportService.queryParams.to
      ? NgbDateConverter.getNgbDate(
          moment(parseInt(this.reportService.queryParams.to * 1000 + '', 10)).format(
            MainConstants.REPORTS.DATE_FORMAT_MOMENT
          )
        )
      : null;
  }

  getSelectedProducts(ids) {
    const result = [];
    for (const id of ids) {
      const product = this.products.find((x) => x.id === +id);
      if (product) {
        result.push(product);
      }
    }
    return result;
  }

  getDateString(date) {
    return date ? date.year + '/' + date.month + '/' + date.day : '';
  }

  updateReports() {
    if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
      return;
    }

    if (this.updateUserTimeout) {
      clearTimeout(this.updateUserTimeout);
    }

    this.updateUserTimeout = setTimeout(() => {
      const queryParams = {
        from: this.fromDate ? moment(this.getDateString(this.fromDate), 'YYYY/MM/DD').unix() : '',
        to: this.toDate ? moment(this.getDateString(this.toDate), 'YYYY/MM/DD').unix() : '',
        products: this.selectedProduct ? this.selectedProduct.map((item) => item.id).join() : '',
      };
      this.router.navigate(['/reports'], { queryParams: queryParams });

      this.reportService.getReports(queryParams).subscribe((response) => {
        this.reports = response;
        this.loadData();
      });
    }, MainConstants.INLINE_EDIT_TIMEOUT);
  }

  /**
   * download csv file
   * */
  exportReportsHistory() {
    this.reportService.downloadReport().subscribe(
      (data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        this.downloadReportFile(url, MainConstants.REPORTS.EXPORTED_HISTORY_DATA_FILE_NAME);
      },
      (error) => {
        this.translate.get('REPORTS.EXPORT_FAILURE_MESSAGE').subscribe((res: string) => {
          this.toastMessageService.showError(res);
        });
      }
    );
  }

  /**
   * create and download csv file
   * */
  exportReports() {
    const rows = [];

    const productRow = [];
    let productIndex = 0;
    /**
     * goes through products, products names push to first row with unshift
     * */
    for (const product of this.products) {
      productRow.push(product.name);
      productRow.push('');

      /**
       * goes through reports by product, push every reports to the proper row and column
       * */
      const reports = this.reportService.reportsByProduct[product.id];
      for (let reportIndex = 0; reportIndex < reports.length; reportIndex++) {
        /**
         * create new row if not exists
         * */
        if (rows.length <= reportIndex) {
          /**
           * there are missed place, so push empty value that places, and finally push current value
           * */
          if (productIndex > 0) {
            rows.push([]);
            for (let emptyCellIndex = 0; emptyCellIndex < productIndex; emptyCellIndex++) {
              rows[reportIndex].push('', '');
            }
            rows[reportIndex].push(reports[reportIndex].name_en, reports[reportIndex].amount);
          } else {
            /**
             * there is not missed cell, just push data to next place
             * */
            rows.push([reports[reportIndex].name_en, reports[reportIndex].amount]);
          }
        } else {
          /**
           * row exist, push report to next cell, in while push empty value to missed cells if needed
           *
           * productIndex * 2 because of every products goes to two cells
           * */
          while (productIndex * 2 > rows[reportIndex].length) {
            rows[reportIndex].push('', '');
          }
          rows[reportIndex].push(reports[reportIndex].name_en, reports[reportIndex].amount);
        }
      }

      productIndex++;
    }
    /**
     * products names push to first row
     * */
    rows.unshift(productRow);

    let csvContent = 'data:text/csv;charset=utf-8,';
    rows.forEach(function (rowArray) {
      const row = rowArray.join(', ');
      csvContent += row + '\r\n';
    });

    this.downloadReportFile(csvContent, MainConstants.REPORTS.EXPORTED_DATA_FILE_NAME);
  }

  downloadReportFile(csvContent, fileName) {
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link); // Required for FF
    link.click();

    this.translate
      .get(
        fileName === MainConstants.REPORTS.EXPORTED_HISTORY_DATA_FILE_NAME
          ? 'REPORTS.EXPORT_HISTORY_SUCCESS_MESSAGE'
          : 'REPORTS.EXPORT_SUCCESS_MESSAGE'
      )
      .subscribe((res: string) => {
        this.toastMessageService.showSucccess(res);
      });
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
