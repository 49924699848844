import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users.component';
import { AgGridModule } from 'ag-grid-angular';
import { UsersService } from './services/users.service';
import { AgStatusUserCellRendererComponent } from './components/ag-status-user-cell-renderer/ag-status-user-cell-renderer.component';
import { FormsModule } from '@angular/forms';
import { AgNgSelectCellEditorComponent } from './components/ag-ng-select-cell-editor/ag-ng-select-cell-editor.component';
import { RoleService } from './services/role.service';
import { SelectorDropdownComponent } from './components/role-selector-dropdown/selector-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { UsersResolve } from './services/user.resolve';
import { SharedModule } from '../shared/shared.module';
import { AgPinCellEditorComponent } from './components/ag-pin-cell-editor/ag-pin-cell-editor.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, TranslateModule, AgGridModule],
  declarations: [
    UsersComponent,
    AgStatusUserCellRendererComponent,
    AgNgSelectCellEditorComponent,
    SelectorDropdownComponent,
    AgPinCellEditorComponent,
  ],
  providers: [UsersService, RoleService, UsersResolve],
})
export class UsersModule {}
