<main class="manage-services-container">
    <div class="align-center d-flex justify-content-center">
        <div class="aligned-center">
            <div class="info">
                {{ 'MANAGE_SERVICES.INFO' | translate }}
            </div>

            <div class="choices d-flex justify-content-center">
                <a
                    [routerLink]="'../' + PAGE_URLS.PRODUCTS.MANAGE_PRODUCT_CATEGORIES"
                    class="products"
                >
                    <div class="icon"></div>
                    <div class="text">
                        {{ 'MANAGE_SERVICES.PRODUCTS' | translate }}
                    </div>
                </a>
                <a
                    [routerLink]="'../' + PAGE_URLS.PRODUCTS.MANAGE_SUPPORT_CATEGORIES"
                    class="support"
                >
                    <div class="icon"></div>
                    <div class="text">
                        {{ 'MANAGE_SERVICES.SUPPORT' | translate }}
                    </div>
                </a>
            </div>
        </div>
    </div>
</main>
