import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

/** Store loader shown property*/
@Injectable()
export class LoaderService {
  /** Store show property*/
  private loaderSubject = new BehaviorSubject<LoaderState>({show: 0, showInHeader: 0});
  /** Store observable, from other components can subscribe to it*/

  public skipNextLoading = false;

  loaderState = this.loaderSubject.asObservable();

  /** Set default state of show, set to true*/
  constructor() {
  }

  show() {
    this.updateState('show', 'increase');
  }

  hide() {
    this.updateState('show', 'decrease');
  }

  showInHeader() {
    this.updateState('showInHeader', 'increase');
    this.skipNextLoading = true;
  }

  hideInHeader() {
    this.updateState('showInHeader', 'decrease');
  }

  private updateState(property: keyof LoaderState, type: 'increase' | 'decrease') {
    const loaderState = this.loaderSubject.value;

    if (type === 'increase') {
      loaderState[property]++;
    } else if (type === 'decrease') {
      loaderState[property]--;
    }

    this.loaderSubject.next(loaderState);
  }
}

/** Specify LoaderState properties*/
export interface LoaderState {
  /** store show value*/
  show: number;
  showInHeader: number;
}
