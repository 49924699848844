<div [ngClass]="{ 'product-card-container': !isIt, 'it-card-container': isIt }">
    <ng-container *ngIf="!new">
        <ng-container *ngIf="editable">
            <app-product-form
                [isIt]="isIt"
                [product]="product"
                (submitted)="updateProduct($event, product)"
                (cancel)="onCancel()"
            >
            </app-product-form>
        </ng-container>

        <ng-container *ngIf="!editable">
            <div class="ey-card" [ngStyle]="getImageUrl(product.image)">
                <div class="header d-flex justify-content-between">
                    <button class="edit-icon" role="button" (click)="onEdit()"></button>

                    <!--                    <div class="drag-icon">-->
                    <!-- TODO: add drag and drop -->
                    <!--                    </div>-->

                    <button
                        class="close-icon"
                        role="button"
                        (click)="deleteProduct(product)"
                    ></button>
                </div>
                <a [routerLink]="product.id" style="text-decoration: none">
                    <div class="it-icon" *ngIf="isIt">
                        <img src="/assets/images/icon_has_support.svg" />
                    </div>
                    <div class="body">
                        <div>
                            <h4 class="name">
                                {{ product.name }}
                            </h4>
                        </div>
                    </div>
                </a>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="new">
        <app-product-form [isIt]="isIt" (submitted)="addProduct($event)" (cancel)="onCancel()">
        </app-product-form>
    </ng-container>
</div>
