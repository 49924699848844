<input
    #pinInput="ngModel"
    class="pin-input"
    [class.error]="pinInput.errors"
    type="text"
    [(ngModel)]="pinCode"
    minlength="4"
    maxlength="4"
    [pattern]="'^[0-9]{4}$'"
/>
