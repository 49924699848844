import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Room } from '../classes/room';
import { HttpService } from '../../../services/http.service';
import { MainConstants } from '../../../constant';

@Injectable()
export class RoomsService {
  constructor(private httpService: HttpService) {}

  getRooms(): Observable<Room[]> {
    return this.httpService.get(MainConstants.API_END_POINTS.ROOMS);
  }

  getRoom(id: number): Observable<Room> {
    return this.httpService.get(MainConstants.API_END_POINTS.ROOMS + `/${id}`);
  }

  addRoom(data: { name_en: string; description_en: string; position: number }): Observable<Room> {
    return this.httpService.post(MainConstants.API_END_POINTS.ROOMS, data);
  }

  updateRoom(id: number, data: { name_en?: string; description_en?: string }): Observable<Room> {
    return this.httpService.patch(MainConstants.API_END_POINTS.ROOMS + `/${id}`, data);
  }

  deleteRoom(id: number): Observable<Room> {
    return this.httpService.delete(MainConstants.API_END_POINTS.ROOMS + `/${id}`);
  }

  sortRooms(data: { id: number; position: number }[]): Observable<Room[]> {
    return this.httpService.patch(MainConstants.API_END_POINTS.ROOMS_POSITION_UPDATE, {
      positions: data,
    });
  }
}
