import { User } from '../../../classes/user';
import { Room } from '../../manage-rooms/classes/room';
import { FreeText } from './freeText';
import { MainConstants } from '../../../constant';
import { SubProductOrder } from './subProductOrder';

export class Order {
  public constructor(
    public id: number = 0,
    public created_at: number = new Date().getTime(),
    public users_id_owner: number = 1,
    public is_it: boolean = false,
    public user_claimed_by: User = new User(),
    public user_owner: User = new User(),
    public status: string = MainConstants.ORDER_STATUS.ORDERED,
    public room: Room = new Room(),
    public ordered_products: SubProductOrder[] = [],
    public free_texts: FreeText[] = []
  ) {}
}
