import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { OrdersService } from '../../services/orders.service';

@Injectable()
export class OrderDetailResolve {
  constructor(
    private orderService: OrdersService,
    private route: ActivatedRoute
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.orderService.getOrder(route.paramMap.get('orderId'));
  }
}
