import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../../../classes/product';

@Component({
  selector: 'app-manage-support-list',
  templateUrl: './manage-support-list.component.html',
  styleUrls: ['./manage-support-list.component.scss'],
})
export class ManageSupportListComponent implements OnInit {
  subProducts: Product[];

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.getSubProducts();
  }

  getSubProducts() {
    this.activatedRoute.data.subscribe((response) => {
      this.subProducts = response.subProducts;
    });
  }
}
