/** Main constants should store here*/
export const MainConstants = {
  LOADER_TIMEOUT: 1000,
  INLINE_EDIT_TIMEOUT: 1000,
  ORDER_LIST_POLL_DELAY: 20000,
  ORDER_REMINDER_DELAY: 30000,
  TOAST_TIMEOUT: 10000,
  PASSWORD_VALIDATOR: '^(?=.*[A-Z].*)(?=.*[0-9].*)(?=.*[a-z].*).{8,}$',
  API_END_POINTS: {
    ORDERS: '/admin/orders',
    PRODUCTS: '/admin/products',
    PRODUCTS_POSITION_UPDATE: '/admin/products/update-positions',
    IT_PRODUCTS: '/admin/it-products',
    IT_PRODUCTS_POSITION_UPDATE: '/admin/it-products/update-positions',
    SUB_PRODUCTS: '/admin/sub-products',
    SUB_PRODUCTS_POSITION_UPDATE: '/admin/sub-products/update-positions',
    ROOMS: '/admin/rooms',
    ROOMS_POSITION_UPDATE: '/admin/rooms/update-positions',
  },
  PAGE_URLS: {
    LOGIN: 'login',
    CONFIRM_REGISTRATION: 'confirm-registration',
    ORDERS: 'orders',
    MANAGE_ROOMS: 'manage-rooms',
    USERS: 'users',
    REPORTS: 'reports',
    PRODUCTS: {
      MANAGE_SERVICES: 'manage-services',
      MANAGE_PRODUCT_CATEGORIES: 'manage-services/product-categories',
      MANAGE_SUPPORT_CATEGORIES: 'manage-services/support-categories',
    },
  },
  ORDER_STATUS: {
    ORDERED: 'ORDERED',
    MADE: 'BEING_MADE',
    DELIVERED: 'DELIVERED',
  },
  ROLE: {
    CUSTOMER: 'customer',
    HOST: 'host',
    ADMIN: 'admin',
  },
  REPORTS: {
    DATE_FORMAT: 'dd/mm/yyyy',
    DATE_FORMAT_MOMENT: 'DD/MM/YYYY',
    EXPORTED_DATA_FILE_NAME: 'reports.csv',
    EXPORTED_HISTORY_DATA_FILE_NAME: 'reports-history.csv',
  },
};
