<div class="users-header">
    <div class="user-add" (click)="showUserForm()">
        <img src="/assets/images/icon_plus.svg" />
        {{ 'USERS.ADD_USER' | translate }}
    </div>
    <input
        [(ngModel)]="filterValue"
        (ngModelChange)="setUsersFilter($event)"
        class="user-search"
        placeholder="{{ 'USERS.SEARCH' | translate }}"
    />
</div>
<form #userForm="ngForm">
    <div class="users-add-form m-0" [ngClass]="{ 'd-none': !showAddUserForm }">
        <div class="">
            {{ 'USERS.ADD_USER' | translate }}
        </div>
        <div class="">
            <input
                [(ngModel)]="newUser.name"
                placeholder="{{ 'USERS.NAME' | translate }}"
                required
                name="first_name"
                class="user-first-name users-input-default"
                type="text"
            />
        </div>
        <div class="">
            <input
                [(ngModel)]="newUser.email"
                required
                name="email"
                email
                placeholder="{{ 'USERS.EMAIL_PLACEHOLDER' | translate }}"
                class="user-email users-input-default"
                type="text"
                [ngClass]="{ error: errors.email }"
            />
        </div>
        <div *ngIf="deviceService.isMobile()" class="w-100"></div>
        <div>
            <app-selector-dropdown
                class="user-role"
                [placeholderText]="'USERS.ROLE'"
                [data]="this.roles"
                [defaultSelection]="newUser.role"
                (selectedChange)="setRole($event)"
            >
            </app-selector-dropdown>
        </div>
        <div>
            <app-selector-dropdown
                class="user-role"
                [placeholderText]="'USERS.ROOM'"
                [data]="this.rooms"
                [clearable]="true"
                [defaultSelection]="newUser.room"
                (selectedChange)="setRoom($event)"
            >
            </app-selector-dropdown>
        </div>
        <div class="">
            <input
                [(ngModel)]="newUser.email_token"
                [required]="!!newUser.room"
                name="email_token"
                minlength="4"
                maxlength="4"
                [pattern]="'^[0-9]{4}$'"
                [placeholder]="'USERS.PIN' | translate"
                type="text"
                class="users-input-default user-pin"
            />
        </div>
        <div class="user-actions">
            <button (click)="cancelUserForm()" class="user-btn-cancel">
                {{ 'USERS.CANCEL' | translate }}
            </button>
            <button
                [disabled]="!userForm.valid || !newUser.role"
                (click)="saveUserForm()"
                class="user-btn-ok"
            >
                {{ 'USERS.OK' | translate }}
            </button>
        </div>
    </div>
</form>

<div class="users-table-container" [ngClass]="{ 'user-form-opened': !showAddUserForm }">
    <ag-grid-angular class="ag-theme-bootstrap users-table" [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
