import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HorizontalScrollDirective } from './directives/horizontal-scroll.directive';
import { MinTrimLengthDirective } from './directives/min-trim-length.directive';
import { AddButtonComponent } from './components/add-button/add-button.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { ModalService } from '../../components/modal/modal.service';
import { NgbDropdownModule, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageUploadComponent } from '../../components/image-upload/image-upload.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbDropdownModule,
    TranslateModule,
    NgSelectModule,
    NgbModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  declarations: [
    HorizontalScrollDirective,
    MinTrimLengthDirective,
    AddButtonComponent,
    ImageUploadComponent,
    ModalComponent,
  ],
  exports: [
    AddButtonComponent,
    ImageUploadComponent,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    HorizontalScrollDirective,
    MinTrimLengthDirective,
    NgbDropdownModule,
    NgbModule,
    NgSelectModule,
    LottieModule,
  ],
  providers: [ModalService],
})
export class SharedModule {}
