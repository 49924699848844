import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../../classes/user';
import { HttpService } from '../../../services/http.service';

@Injectable()
export class UsersService {
  users: User[];

  constructor(private httpService: HttpService) {}

  getUserList(): Observable<User[]> {
    return this.httpService.get('/admin/users').pipe(
      map((data) => {
        this.users = data;
        return data.map((x) => Object.assign({}, x));
      })
    );
  }

  deleteUser(id): Observable<User> {
    return this.httpService.delete(`/admin/users/${id}`);
  }

  createUser({ name, email, role, room, email_token }: User): Observable<User> {
    return this.httpService.post('/admin/users', {
      name,
      email,
      role,
      room_id: room,
      email_token,
    });
  }

  resetPassword(id) {
    return this.httpService.post(`/admin/users/${id}/password-reset`);
  }

  updateUser(id, user): Observable<User> {
    return this.httpService.patch(`/admin/users/${id}`, user);
  }
}
