import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.injector.get(AuthService);

    const apiCall = request.urlWithParams.indexOf(environment.apiUrl) > -1;
    const isLogin = request.urlWithParams.indexOf('login') > -1;
    if (apiCall && !isLogin) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${auth.getAuthToken()}`,
        },
      });
    }
    return next.handle(request);
  }
}
