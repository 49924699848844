import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../classes/product';
import { HttpService } from '../../../services/http.service';
import { MainConstants } from '../../../constant';

@Injectable()
export class ItProductsService {
  constructor(private httpService: HttpService) {}

  getProducts(): Observable<Product[]> {
    return this.httpService.get(MainConstants.API_END_POINTS.IT_PRODUCTS);
  }

  addProduct(data): Observable<Product> {
    return this.httpService.post(MainConstants.API_END_POINTS.IT_PRODUCTS, data);
  }

  updateProduct(id: number, data): Observable<Product> {
    return this.httpService.patch(MainConstants.API_END_POINTS.IT_PRODUCTS + `/${id}`, data);
  }

  deleteProduct(id: number): Observable<Product> {
    return this.httpService.delete(MainConstants.API_END_POINTS.IT_PRODUCTS + `/${id}`);
  }

  sortProducts(data: { id: number; position: number }[]): Observable<Product[]> {
    return this.httpService.patch(MainConstants.API_END_POINTS.IT_PRODUCTS_POSITION_UPDATE, {
      positions: data,
    });
  }
}
