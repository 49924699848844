import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ReportsService } from './services/reports.service';
import { forkJoin, Observable } from 'rxjs';

@Injectable()
export class ReportsResolve {
  constructor(private reportsService: ReportsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const queryParams = {
      from: route.queryParamMap.get('from') || '',
      to: route.queryParamMap.get('to') || '',
      products: route.queryParamMap.get('products') || '',
    };

    const reports = this.reportsService.getReports(queryParams);
    const products = this.reportsService.getProducts();

    return forkJoin([reports, products]);
  }
}
