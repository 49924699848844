<div class="products-selector-dropdown">
    <div
        ngbDropdown
        class="d-inline-block"
        #productDopDown="ngbDropdown"
        (openChange)="dropDownOpenChange(productDopDown.isOpen())"
    >
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
            <span class="selected-product-name" *ngIf="showAllProducts">{{
                products[0].name
            }}</span>
            <span *ngIf="!showAllProducts">
                <span
                    class="selected-product-name"
                    *ngFor="let selected of selectedProduct; let i = index"
                >
                    {{ selected.name }} <span *ngIf="i < selectedProduct.length - 1">,</span>
                </span>
            </span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <div class="row">
                <div class="products-list col-7">
                    <div class="product-list-item row" *ngFor="let product of products">
                        <span class="col-9">{{ product.name }}</span>
                        <div class="col-3">
                            <div
                                class="icon-container"
                                (click)="setProducts($event, product)"
                                [ngClass]="{ selected: product.selected }"
                            >
                                <img
                                    *ngIf="!product.selected"
                                    src="/assets/images/order_not_checked.svg"
                                />
                                <img
                                    *ngIf="product.selected"
                                    src="/assets/images/order_checked.svg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="submit-products col-5">
                    <button (click)="saveProductFilter()">
                        {{ 'USERS.OK' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
