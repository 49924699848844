import { Injectable } from '@angular/core';
import { Room } from './classes/room';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RoomsService } from './services/rooms.service';
import { Observable } from 'rxjs';

@Injectable()
export class RoomsResolve {
  constructor(private roomsService: RoomsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Room[]> | Promise<Room[]> | Room[] {
    return this.roomsService.getRooms();
  }
}
