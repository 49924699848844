import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../modules/auth/auth.service';

@Directive({ selector: '[appIsAdmin]' })
export class IsAdminDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  @Input()
  set appIsAdmin(allow: boolean) {
    if (
      (this.authService.loggedInUser && this.authService.loggedInUser.role === 'admin') === allow
    ) {
      // If condition is true add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // Else remove template from DOM
      this.viewContainer.clear();
    }
  }
}
