import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-products-selector-dropdown',
  templateUrl: './products-selector-dropdown.component.html',
  styleUrls: ['./products-selector-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductsSelectorDropdownComponent implements OnInit {
  @Input('withDefaultValue') withDefaultValue = false;
  @Input('products') products;
  @Input('selectedProduct') selectedProduct;
  @Output() selectedProductChange = new EventEmitter();

  private selectedWhenOpened;

  constructor() {}

  ngOnInit() {
    this.products = this.products.map((x) => Object.assign({}, x));
    this.products.unshift({ name: 'all products', id: 0 });
    if (this.selectedProduct) {
      for (const selectedProduct of this.selectedProduct) {
        this.products.find((x) => x.id === selectedProduct.id).selected = true;
      }

      if (this.selectedProduct.length === this.products.length - 1) {
        this.products[0].selected = true;
      }
    }
  }

  setProducts(event, product) {
    event.stopPropagation();
    product.selected = !product.selected;

    if (!product.id) {
      for (const productItem of this.products) {
        if (!!productItem.id) {
          productItem.selected = product.selected;
        }
      }
    } else {
      this.setAllProducts();
    }
  }

  get getSelectedProducts() {
    const selectedIds = [];
    for (const product of this.products) {
      if (product.selected && !!product.id) {
        selectedIds.push(product);
      }
    }
    return selectedIds.length > 0 ? selectedIds : null;
  }

  get showAllProducts() {
    return this.selectedProduct ? this.selectedProduct.length === this.products.length - 1 : true;
  }

  dropDownOpenChange(isOpen) {
    if (!isOpen) {
      this.selectedProduct = this.selectedWhenOpened;
      for (const product of this.products) {
        product.selected = this.selectedProduct
          ? !!this.selectedProduct.find((x) => x.id === product.id)
          : false;
      }
    } else {
      this.selectedWhenOpened = this.selectedProduct
        ? this.selectedProduct.map((x) => Object.assign({}, x))
        : null;
      this.setAllProducts();
    }
  }

  setAllProducts() {
    let productIndex = 1;
    let allSelected = true;

    while (productIndex < this.products.length && allSelected) {
      allSelected = this.products[productIndex].selected;
      productIndex++;
    }

    this.products[0].selected = allSelected;
  }

  saveProductFilter() {
    this.selectedProduct = this.getSelectedProducts;
    this.selectedWhenOpened = this.getSelectedProducts;
    this.selectedProductChange.emit(this.selectedProduct);
  }
}
