<div
    class="report-card-inner"
    [ngClass]="{
        mobile: deviceService.isMobile()
    }"
>
    <div class="report-product-title">
        <div class="text-left">{{ product.name }}</div>
        <div class="text-right">{{ productsAmount }}</div>
    </div>

    <div class="report-products-type" *ngFor="let report of reports">
        <div class="text-right">{{ report.name_en }}</div>
        <div class="report-amount">{{ report.amount }}</div>
    </div>
</div>
