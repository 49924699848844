import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Room } from '../../classes/room';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-room-form',
  templateUrl: './room-form.component.html',
  styleUrls: ['./room-form.component.scss'],
})
export class RoomFormComponent implements AfterViewInit {
  @Input() room = new Room();
  @Output() submitted = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @ViewChild('name', { static: false }) nameInput: ElementRef;
  @ViewChild('form', { static: false }) form: NgForm;

  constructor() {}

  ngAfterViewInit() {
    this.nameInput.nativeElement.focus();
  }

  close() {
    this.cancel.emit();
  }

  onSubmit(event) {
    if (this.form.valid) {
      this.submitted.emit(
        new Room(0, this.form.form.value.name, this.form.form.value.description, this.room.position)
      );
    }
  }
}
