import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

/** CanActive guard to investigate every state change*/
@Injectable()
export class AuthGuard {
  /** Instantiates outer classes*/
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  /** Implement CanActivate interface, block navigate to app, if not logged in, expect login page*/
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.isLoggedIn() && state.url.includes('login')) {
      this.router.navigate(['/orders']);
      return true;
    } else if (
      this.auth.isLoggedIn() ||
      state.url.includes('login') ||
      state.url.includes('confirm-registration')
    ) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
