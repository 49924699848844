import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastMessageModel } from './toast-message.model';

@Injectable()
export class ToastMessageService {
  constructor() {}

  messageId = 0;
  private alertEvent: Subject<ToastMessageModel> = new Subject();

  getAlertEvent() {
    return this.alertEvent.asObservable();
  }

  showSucccess(message: string) {
    this.showToast('success', message);
  }

  showInfo(message: string) {
    this.showToast('info', message);
  }

  showWarning(message: string) {
    this.showToast('warning', message);
  }

  showError(message: string) {
    this.showToast('danger', message);
  }

  showToast(level: string, message: string) {
    const toast: ToastMessageModel = {
      id: this.messageId++,
      type: level,
      message: message,
    };
    this.alertEvent.next(toast);
  }
}
