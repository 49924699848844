import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Product } from '../../../../classes/product';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductFormComponent implements AfterViewInit {
  @Input() product: Product = new Product();
  @Input() isIt: number;
  @Output() submitted = new EventEmitter<Product>();
  @Output() cancel = new EventEmitter();

  @ViewChild('name', { static: false }) nameInput: ElementRef;
  @ViewChild('form', { static: false }) form: NgForm;

  public productImageUrl;

  constructor() {}

  ngAfterViewInit() {
    this.nameInput.nativeElement.focus();
  }

  onCancel() {
    this.cancel.emit();
  }

  getImageUrl(url) {
    return url
      ? {
          'background-image': `url('${url}')`,
        }
      : {};
  }

  onImageUpload({ image }) {
    this.productImageUrl = image;
  }

  onSubmit(event) {
    // Don't allow the user to create new lines, submit the form instead
    event.preventDefault();

    if (this.form.valid) {
      // clear the form
      this.submitted.emit(new Product(0, this.form.form.value.name, null, this.productImageUrl));
    }
  }
}
