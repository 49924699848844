<div [class.loader-hidden]="!showGlobalLoader">
    <div class="loader-overlay">
        <div *ngIf="showGlobalLoader" class="global-loader-container">
            <app-global-loader [height]="400" [width]="200"></app-global-loader>
        </div>
    </div>
</div>

<app-header [ngClass]="{ 'show-header': showHeader }"></app-header>

<app-toast-message [alerts]="messageList"></app-toast-message>

<div [ngClass]="{ 'content-container': showHeader }">
    <router-outlet></router-outlet>
</div>
