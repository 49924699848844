import { Component, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-ag-pin-cell-editor',
  templateUrl: './ag-pin-cell-editor.component.html',
  styleUrls: ['./ag-pin-cell-editor.component.scss'],
})
export class AgPinCellEditorComponent implements ICellEditorAngularComp {
  @ViewChild('pinInput', { static: false }) pinInput: NgModel;
  params: ICellEditorParams;
  pinCode: string;

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.pinCode = params.value;
  }

  getValue() {
    return this.pinCode;
  }

  isPopup(): boolean {
    return false;
  }

  isCancelAfterEnd(): boolean {
    return !!this.pinInput.errors;
  }
}
