import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { MinTrimLengthValidator } from '../validators/min-trim-length.validator';

/**
 * This validator works like "minlength" but it does not count whitespace characters
 *
 * @export
 * @class MinTrimLengthDirective
 * @implements {Validator}
 */
@Directive({
  selector: '[appMinTrimLength]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinTrimLengthDirective, multi: true }],
})
export class MinTrimLengthDirective implements Validator {
  // This is how you get the directive's value
  @Input() appMinTrimLength: number;

  validate(control: AbstractControl): { [key: string]: any } {
    this.appMinTrimLength = Number(this.appMinTrimLength);

    if (
      !this.appMinTrimLength ||
      !Number.isInteger(this.appMinTrimLength) ||
      Number(this.appMinTrimLength) < 1
    ) {
      throw new Error("'appMinTrimLength' directive must have a positive non-zero value");
    }

    const valFn = MinTrimLengthValidator(this.appMinTrimLength);
    return valFn(control);
  }
}
