import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-global-loader',
  template:
    '<ng-lottie [options]="lottieConfig" [width]="widthString" [height]="heightString"></ng-lottie>',
})
export class GlobalLoaderComponent {
  public lottieConfig: AnimationOptions;
  @Input() height: number;
  @Input() width: number;

  get heightString(): string {
    return `${this.height}px`;
  }

  get widthString(): string {
    return `${this.width}px`;
  }

  constructor() {
    this.lottieConfig = {
      path: 'assets/loading.json',
      autoplay: true,
      loop: true,
    };
  }
}
