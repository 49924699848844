import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastMessageService } from './toast-message.service';
import { ToastMessageComponent } from './toast-message.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ToastMessageComponent],
  exports: [ToastMessageComponent],
  providers: [ToastMessageService],
})
export class ToastMessageModule {}
