import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ReportsComponent } from './reports.component';
import { ReportsService } from './services/reports.service';
import { ReportsResolve } from './reports.resolve';
import { ProductsSelectorDropdownComponent } from './components/products-selector-dropdown/products-selector-dropdown.component';
import { DatepickerPopupComponent } from './components/datepicker-popup/datepicker-popup.component';
import { ReportCardComponent } from './components/report-card/report-card.component';

@NgModule({
  declarations: [
    ReportsComponent,
    ProductsSelectorDropdownComponent,
    DatepickerPopupComponent,
    ReportCardComponent,
  ],
  imports: [CommonModule, TranslateModule, SharedModule, FormsModule],
  providers: [ReportsService, ReportsResolve],
})
export class ReportsModule {}
