import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { User } from '../../../../classes/user';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-ag-status-user-cell-renderer',
  templateUrl: './ag-status-user-cell-renderer.component.html',
  styleUrls: ['./ag-status-user-cell-renderer.component.scss'],
})
export class AgStatusUserCellRendererComponent implements AgRendererComponent {
  user: User;
  params: ICellRendererParams;

  constructor(private userService: UsersService) {}

  refresh(params: any): boolean {
    return undefined;
  }

  agInit(params: ICellRendererParams): void {
    this.user = params.data;
    this.params = params;
  }

  setUserStatus() {
    this.params.setValue(this.user.is_active);
  }
}
