<ng-select
    class="custom"
    [class.ag-grid-style]="tableLayout"
    [items]="data"
    bindLabel="name"
    [(ngModel)]="selected"
    [clearable]="clearable"
    [searchable]="false"
    (change)="emitValue()"
    [placeholder]="placeholderText | translate"
>
</ng-select>
