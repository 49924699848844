import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SubProduct } from '../classes/subProduct';
import { SubProductsService } from '../services/sub-products.service';

@Injectable()
export class SubProductsResolve {
  constructor(private subProductsService: SubProductsService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<SubProduct[]> | Promise<SubProduct[]> | SubProduct[] {
    return this.subProductsService.getSubProducts(route.params['productId']);
  }
}
