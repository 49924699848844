export class Helper {
  /** Get element by ID. Return <null> if not found. */
  static getElmById(array: any[], id: number) {
    const elements = array.filter((e) => e.id === id);
    if (elements.length === 1) {
      return elements[0];
    } else if (elements.length === 0) {
      return null;
    } else {
      throw new Error('more elements with the same ID');
    }
  }

  /** Delete element by Id. Return <null> if not found. */
  static delElmById(array: any[], id: number) {
    const elements = array.filter((e) => e.id === id);
    if (elements.length === 1) {
      const position = array.map((e) => e.id).indexOf(id);
      array.splice(position, 1);
      return array;
    } else if (elements.length === 0) {
      return null;
    } else {
      throw new Error('more elements with the same ID.');
    }
  }

  /** Generate unused ID. */
  static genId(array: { id: number }[]) {
    const ids = array.map((el) => el.id);
    let i = 1;
    while (ids.indexOf(i) !== -1) {
      i++;
    }
    return i;
  }
}
