import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UsersService } from './users.service';
import { Observable } from 'rxjs';
import { User } from '../../../classes/user';

@Injectable()
export class UsersResolve {
  constructor(private usersService: UsersService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<User[]> | Promise<User[]> | User[] {
    return this.usersService.getUserList();
  }
}
