import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageRoomsComponent } from './manage-rooms.component';
import { RoomsService } from './services/rooms.service';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { RoomCardComponent } from './components/room-card/room-card.component';
import { FormsModule } from '@angular/forms';
import { RoomFormComponent } from './components/room-form/room-form.component';
import { RoomsResolve } from './rooms.resolve';

@NgModule({
  declarations: [ManageRoomsComponent, RoomCardComponent, RoomFormComponent],
  imports: [CommonModule, TranslateModule, SharedModule, FormsModule, SharedModule],
  exports: [ManageRoomsComponent],
  providers: [RoomsService, RoomsResolve],
})
export class ManageRoomsModule {}
