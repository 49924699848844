<div
    class="order-card-inner"
    #orderCardInner
    [ngClass]="{
        mobile: deviceService.isMobile(),
        delivered: orderStatusDelivered
    }"
>
    <div class="order-room-detail">
        <div class="order-room-name">
            {{ order.room.name }}
        </div>
        <div class="order-created-at">
            {{ order.created_at | date: 'MMM d, y HH:mm' }}
        </div>
        <div class="order-room-description">
            {{ order.user_owner.name }}
        </div>
    </div>

    <div *ngIf="!orderStatusOrdered || order.is_it" class="order-card-claimed-by">
        <div *ngIf="orderStatusMade" class="claimed_by">
            <img src="/assets/images/claimed_by_icon.svg" /> {{ 'ORDERS.CLAIMED_BY' | translate }}
            {{ userClaimedName }}
        </div>

        <div *ngIf="orderStatusDelivered" class="delivered_by">
            <img src="/assets/images/delivered_by_icon.svg" />
            {{ 'ORDERS.DELIVERED_BY' | translate }} {{ userClaimedName }}
        </div>

        <div *ngIf="order.is_it" class="support-order">
            <img src="/assets/images/icon_has_support.svg" />
            {{ 'MANAGE_SERVICES.SUPPORT' | translate }}
        </div>
    </div>

    <div class="order-details" #orderDetails>
        <div
            class="order-details-item row m-0"
            [ngClass]="{ 'out-of-stock': orderProduct.available === false }"
            *ngFor="let orderProduct of order.ordered_products"
        >
            <div class="col-9">{{ orderProduct.amount }} {{ orderProduct.name }}</div>
            <div class="col-3">
                <img
                    src="/assets/images/icon_not_available_checked.svg"
                    *ngIf="showOutOfStockCheckedSign(orderProduct)"
                    (click)="setProductAvailable(orderProduct)"
                />

                <img
                    src="/assets/images/icon_not_available.svg"
                    *ngIf="showOutOfStockUnCheckedSign(orderProduct)"
                    (click)="setProductAvailable(orderProduct)"
                />
            </div>
        </div>

        <div class="order-details-item" *ngFor="let subProduct of order.free_texts">
            {{ subProduct.free_text }}
        </div>
    </div>

    <div *ngIf="orderStatusOrdered" (click)="claimOrder($event)" class="order-button button-claim">
        {{ 'ORDERS.CLAIM' | translate }}
    </div>

    <div *ngIf="orderStatusMade" (click)="deliverOrder($event)" class="order-button button-made">
        {{ 'ORDERS.READY' | translate }}
    </div>

    <div *ngIf="orderStatusDelivered" class="order-button button-details">
        {{ 'ORDERS.DETAILS' | translate }}
    </div>
</div>
