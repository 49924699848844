import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ModalService {
  constructor(
    private modalService: NgbModal,
    private translate: TranslateService
  ) {}

  open({ bodyText, bodyParam = '', hideFooter = false }): Promise<any> {
    const modalRef = this.modalService.open(ModalComponent);
    this.translate.get(bodyText, { value: bodyParam }).subscribe((res: string) => {
      modalRef.componentInstance.question = res;
      modalRef.componentInstance.hideFooter = hideFooter;
    });

    return modalRef.result;
  }
}
