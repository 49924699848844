<div class="reports-container">
    <div class="reports-header">
        <app-products-selector-dropdown
            [products]="products"
            [(selectedProduct)]="selectedProduct"
            (selectedProductChange)="updateReports()"
        >
        </app-products-selector-dropdown>

        <div class="date-picker-container">
            <app-datepicker-popup
                [(selectedDate)]="fromDate"
                [maxDate]="toDate"
                (selectedDateChange)="updateReports()"
            ></app-datepicker-popup>

            <span class="line-between-date"></span>

            <app-datepicker-popup
                [(selectedDate)]="toDate"
                [minDate]="fromDate"
                (selectedDateChange)="updateReports()"
                [bgColor]="'darken-bg'"
            ></app-datepicker-popup>
        </div>

        <button class="default-button export-btn" (click)="exportReports()">
            <img src="/assets/images/icon_export.svg" />
            {{ 'REPORTS.EXPORT' | translate }}
        </button>

        <button class="default-button export-history-btn" (click)="exportReportsHistory()">
            <img src="/assets/images/icon_export.svg" />
            {{ 'REPORTS.EXPORT_HISTORY' | translate }}
        </button>
    </div>
    <div class="reports-body">
        <div
            *ngIf="!deviceService.isMobile()"
            class="card-container"
            [ngClass]="{ 'no-cards': !reports.length }"
            appHorizontalScroll
            [horizontalExpectEl]="'order-details-item'"
        >
            <!--             dragScroll [snap-disabled]="true">-->
            <app-report-card
                [ngClass]="{ show: reportService.reportsByProduct[product.id].length > 0 }"
                *ngFor="let product of products"
                [product]="product"
                [reports]="reportService.reportsByProduct[product.id]"
            >
            </app-report-card>

            <div class="no-cards-title" *ngIf="!reports.length">
                {{ 'REPORTS.NO_REPORTS_TITLE' | translate }}
            </div>
        </div>

        <div
            *ngIf="deviceService.isMobile()"
            class="card-container mobile"
            [ngClass]="{ 'no-cards': !reports.length }"
        >
            <app-report-card
                *ngFor="let product of products"
                [product]="product"
                [reports]="reportService.reportsByProduct[product.id]"
            >
            </app-report-card>

            <div class="no-cards-title" *ngIf="!reports.length">
                {{ 'REPORTS.NO_REPORTS_TITLE' | translate }}
            </div>
        </div>
    </div>
</div>
