import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

type DropdownCellInputProps = {
  dropdownData: { id: number; name: string }[];
  placeholderText: string;
  bindValue: string;
  clearable?: boolean;
};
type DropdownCellParams = ICellEditorParams & DropdownCellInputProps;

@Component({
  selector: 'app-ag-ng-select-cell-editor',
  templateUrl: './ag-ng-select-cell-editor.component.html',
  styleUrls: ['./ag-ng-select-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgNgSelectCellEditorComponent implements ICellEditorAngularComp {
  params: DropdownCellParams;
  selected: string;

  setSelected(selected: string) {
    this.selected = selected;
  }

  agInit(params: DropdownCellParams) {
    this.params = params;
    this.selected = params.value;
  }

  getValue() {
    return this.selected;
  }

  isPopup(): boolean {
    return true;
  }
}
