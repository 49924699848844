<div class="room-card-container">
    <ng-container *ngIf="!new">
        <ng-container *ngIf="editable">
            <app-room-form
                [room]="room"
                (submitted)="updateRoom($event, room)"
                (cancel)="onCancel()"
            >
            </app-room-form>
        </ng-container>

        <div *ngIf="!editable">
            <div class="ey-card">
                <div class="header">
                    <div class="d-flex justify-content-between">
                        <div>
                            <!--                            <div class="drag-icon">-->
                            <!-- TODO: add drag and drop -->
                            <!--                            </div>-->
                        </div>
                        <div>
                            <button class="edit-icon" role="button" (click)="onEdit()"></button>
                            <button
                                class="close-icon"
                                role="button"
                                (click)="deleteRoom(room)"
                            ></button>
                        </div>
                    </div>
                </div>
                <div class="body">
                    <div>
                        <h4 class="name">
                            {{ room.name }}
                        </h4>
                        <p class="description">
                            {{ room.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="new">
        <app-room-form (submitted)="addRoom($event)" (cancel)="onCancel()"> </app-room-form>
    </ng-container>
</div>
