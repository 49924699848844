import { Component, Input } from '@angular/core';
import { DeviceService } from '../../../../services/device.service';
import { Product } from '../../../manage-services/classes/product';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss'],
})
export class ReportCardComponent {
  @Input() reports = [];
  @Input() product: Product = new Product();

  constructor(public deviceService: DeviceService) {}

  get productsAmount() {
    if (!!this.reports.length) {
      const reportSum = this.reports.reduce((accumulator, currentValue) =>
        accumulator.amount
          ? accumulator.amount + currentValue.amount
          : accumulator + currentValue.amount
      );

      return reportSum.amount ? reportSum.amount : reportSum;
    }

    return '';
  }
}
