import { Injectable } from '@angular/core';
import { MainConstants } from '../../../constant';

export type Role = {
  id: number;
  name: string;
};

@Injectable()
export class RoleService {
  roles: Role[];

  constructor() {
    this.roles = [
      { id: 1, name: MainConstants.ROLE.CUSTOMER },
      { id: 2, name: MainConstants.ROLE.ADMIN },
      { id: 3, name: MainConstants.ROLE.HOST },
    ];
  }

  getRoles() {
    return this.roles;
  }
}
