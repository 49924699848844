<form
    class="ey-card active"
    [ngClass]="{
        editable: !!subProduct || !!productImageUrl,
        'has-image': !!subProduct.image || !!productImageUrl,
        'it-form': isIt
    }"
    #form="ngForm"
    (ngSubmit)="onSubmit($event)"
    [ngStyle]="getImageUrl(subProduct.image ? subProduct.image : productImageUrl)"
>
    <div class="header">
        <div class="d-flex justify-content-between">
            <button class="close-icon" type="button" role="button" (click)="onCancel()"></button>

            <button
                *ngIf="form.valid"
                class="approve-icon"
                type="submit"
                role="button"
                [disabled]="!form.valid"
            ></button>
        </div>
        <div class="d-flex justify-content-center image-upload">
            <app-image-upload
                *ngIf="!isIt"
                (onImageUpload)="onImageUpload($event)"
            ></app-image-upload>
        </div>
    </div>
    <div class="body">
        <input
            type="text"
            class="input"
            title="Name"
            autocomplete="off"
            #name
            name="name"
            ngModel
            [ngModel]="subProduct.name"
            required
            appMinTrimLength="1"
            maxlength="25"
            [placeholder]="'MANAGE_SERVICES.SUB_PRODUCT_FORM.NAME_PLACEHOLDER' | translate"
        />
    </div>
    <div class="ey-card-blur" *ngIf="!!subProduct.image || !!productImageUrl"></div>
</form>
