<main class="manage-rooms">
    <div class="manage-rooms-header">
        <app-add-button
            [text]="'MANAGE_ROOMS.ROOM_LIST.ADD_ROOM'"
            [(clicked)]="showNew"
            (activated)="scrollToEnd()"
        >
        </app-add-button>
    </div>

    <div class="rooms-list-container" appHorizontalScroll #roomsContainer>
        <!-- TODO: add drag and drop -->
        <div class="rooms-list" style="cursor: default">
            <div *ngFor="let room of rooms; let i = index" style="cursor: default">
                <app-room-card [room]="room" [rooms]="rooms" style="cursor: default">
                </app-room-card>
            </div>

            <ng-container *ngIf="showNew">
                <app-room-card
                    [new]="true"
                    [rooms]="rooms"
                    (addingNewCanceled)="onAddingNewCanceled()"
                >
                </app-room-card>
            </ng-container>
        </div>
    </div>
</main>
