import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../../services/http.service';

@Injectable()
export class ReportsService {
  public queryParams;
  public reportsByProduct = {};
  private products;
  private reports;

  constructor(private httpService: HttpService) {}

  getReports(queryParams): Observable<any> {
    this.queryParams = queryParams;
    return this.httpService.get('/admin/reports/ordered-products', queryParams).pipe(
      map((data) => {
        return (this.reports = data);
      })
    );
  }

  getProducts() {
    return this.httpService.get('/admin/products').pipe(
      map((data) => {
        return (this.products = data);
      })
    );
  }

  getReportsByProduct() {
    for (const product of this.products) {
      this.reportsByProduct[product.id] = this.reports.filter((x) => x.products_id === product.id);
    }
  }

  downloadReport() {
    return this.httpService.get('/admin/reports/ordered-products/download', {}, 'text/csv');
  }
}
