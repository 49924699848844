import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';

@NgModule({
  imports: [],
  declarations: [],
  providers: [AuthService, AuthGuard],
})
export class AuthModule {}
