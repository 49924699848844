<form
    class="ey-card active"
    [ngClass]="{
        editable: !!product || !!productImageUrl,
        'has-image': !!product.image || !!productImageUrl,
        'it-form': isIt
    }"
    #form="ngForm"
    (ngSubmit)="onSubmit($event)"
    [ngStyle]="getImageUrl(product.image ? product.image : productImageUrl)"
>
    <div class="header">
        <div class="d-flex justify-content-between">
            <button class="close-icon" type="button" role="button" (click)="onCancel()"></button>

            <button
                *ngIf="form.valid"
                class="approve-icon"
                type="submit"
                role="button"
                [disabled]="!form.valid"
            ></button>
        </div>

        <div class="d-flex justify-content-center image-upload" *ngIf="!isIt">
            <app-image-upload (onImageUpload)="onImageUpload($event)"></app-image-upload>
        </div>
    </div>
    <div class="it-icon" *ngIf="isIt">
        <img src="/assets/images/icon_has_support.svg" />
    </div>
    <div class="body">
        <textarea
            type="text"
            class="input"
            title="Name"
            autocomplete="off"
            #name
            name="name"
            ngModel
            [ngModel]="product.name"
            required
            rows="3"
            appMinTrimLength="1"
            maxlength="30"
            (keydown.enter)="onSubmit($event)"
            [placeholder]="'MANAGE_SERVICES.PRODUCT_FORM.NAME_PLACEHOLDER' | translate"
        >
        </textarea>
    </div>

    <div class="ey-card-blur" *ngIf="!!product.image || !!productImageUrl"></div>
</form>
