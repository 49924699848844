import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubProductsService } from '../../../../../services/sub-products.service';
import { ItSubProductsService } from '../../../../../services/it-sub-products.service';
import { ModalService } from '../../../../../../../components/modal/modal.service';
import { SubProduct } from '../../../../../classes/subProduct';
import { ActivatedRoute } from '@angular/router';
import { Helper } from '../../../../../../../helper';
import { ProductsService } from '../../../../../services/products.service';
import { ItProductsService } from '../../../../../services/it-products.service';
import { Product } from '../../../../../classes/product';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../../../../../../toast-message/toast-message.service';

@Component({
  selector: 'app-sub-product-card',
  templateUrl: './sub-product-card.component.html',
  styleUrls: ['./sub-product-card.component.scss'],
})
export class SubProductCardComponent implements OnInit {
  @Input() isIt: number;
  @Input() subProduct: SubProduct = new SubProduct();
  @Input() subProducts: SubProduct[];
  @Output() productsChange = new EventEmitter<SubProduct[]>();
  editable = false;
  service: SubProductsService | ItSubProductsService;
  productId: number;
  product: Product;

  // Only matter if type new
  @Input() new = false;
  @Output() addingNewCanceled = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private itProductsService: ItProductsService,
    private subProductsService: SubProductsService,
    private itSubProductsService: ItSubProductsService,
    private modalService: ModalService,
    private translate: TranslateService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    if (this.isIt) {
      this.service = this.itSubProductsService;
    } else {
      this.service = this.subProductsService;
    }
    this.route.params.subscribe((params) => {
      this.productId = params['productId'];
    });
  }

  onEdit() {
    this.editable = true;
  }

  onCancel() {
    if (this.new) {
      this.addingNewCanceled.emit();
    } else {
      this.editable = false;
    }
  }

  getImageUrl(url) {
    return url
      ? {
          'background-image': `url('${url}')`,
        }
      : {};
  }

  showSuccess(message: string): void {
    this.translate.get(message).subscribe((res: string) => {
      this.toastMessageService.showSucccess(res);
    });
  }

  showError(message: string): void {
    this.translate.get(message).subscribe((res: string) => {
      this.toastMessageService.showError(res);
    });
  }

  addSubProduct(newSubProduct: SubProduct): void {
    this.subProduct = newSubProduct;
    this.service
      .addSubProduct(this.productId, {
        name_en: newSubProduct.name,
        products_id: this.productId,
        position: this.subProducts.length,
        image: newSubProduct.image,
      })
      .subscribe(
        (addedSubProduct) => {
          this.subProducts.push(addedSubProduct);
          this.editable = false;
          this.addingNewCanceled.emit();
          this.showSuccess('MANAGE_SERVICES.SUB_PRODUCT_LIST.TOAST_MESSAGES.SUCCESSFULLY.ADDED');
        },
        (err) => {
          if (err.status === 400) {
            this.showError('MANAGE_SERVICES.SUB_PRODUCT_LIST.TOAST_MESSAGES.FAILED.EXISTS');
          } else {
            this.showError('MANAGE_SERVICES.SUB_PRODUCT_LIST.TOAST_MESSAGES.FAILED.ADDING');
          }
        }
      );
  }

  updateSubProduct(newSubProduct: SubProduct, original: SubProduct): void {
    this.subProduct = newSubProduct;
    const id = original.id;
    const newName = newSubProduct.name;
    const newImage = newSubProduct.image;

    /** Only send request if there have been any changes */
    if (newName !== original.name || newImage !== original.image) {
      this.service
        .updateSubProduct(id, {
          name_en: newName,
          products_id: this.productId,
          image: newImage,
        })
        .subscribe(
          (_) => {
            const updatedSubProduct = Helper.getElmById(this.subProducts, id);
            updatedSubProduct.name = newName;
            this.editable = false;
            this.showSuccess(
              'MANAGE_SERVICES.SUB_PRODUCT_LIST.TOAST_MESSAGES.SUCCESSFULLY.UPDATED'
            );
          },
          (err) => {
            if (err.status === 400) {
              this.showError('MANAGE_SERVICES.SUB_PRODUCT_LIST.TOAST_MESSAGES.FAILED.EXISTS');
            } else {
              this.showError('MANAGE_SERVICES.SUB_PRODUCT_LIST.TOAST_MESSAGES.FAILED.UPDATING');
            }
          }
        );
    } else {
      this.editable = false;
    }
  }

  deleteSubProduct(subProduct: SubProduct): void {
    this.modalService
      .open({
        bodyText: 'MANAGE_SERVICES.SUB_PRODUCT_LIST.DELETE_SUB_PRODUCT_QUESTION',
        bodyParam: subProduct.name,
      })
      .then(() => {
        this.service.deleteSubProduct(subProduct.id).subscribe(
          () => {
            this.subProducts = Helper.delElmById(this.subProducts, subProduct.id);
            this.showSuccess(
              'MANAGE_SERVICES.SUB_PRODUCT_LIST.TOAST_MESSAGES.SUCCESSFULLY.DELETED'
            );
          },
          (error) => {
            this.showError('MANAGE_SERVICES.SUB_PRODUCT_LIST.TOAST_MESSAGES.FAILED.DELETING');
          }
        );
      })
      .catch(() => {});
  }
}
