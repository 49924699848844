import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

/** Http Service Layer */
@Injectable()
export class HttpService {
  /** Instantiates HttpClient */
  constructor(private http: HttpClient) {}

  /** generate url for backend call */
  generateFullUrl(url) {
    return environment.apiUrl + url;
  }

  /** generate url query params for backend call */
  generateUrlQueryParams(urlParams: Object = null) {
    let Params = new HttpParams();

    if (urlParams) {
      for (const property in urlParams) {
        if (urlParams.hasOwnProperty(property)) {
          Params = Params.append(property, urlParams[property]);
        }
      }
    }

    return Params;
  }

  /** Send HTTP GET request */
  get(url, urlParams: Object = null, responseType?): Observable<any> {
    return this.http.get(this.generateFullUrl(url), {
      params: this.generateUrlQueryParams(urlParams),
      responseType: responseType,
    });
  }

  /** Send HTTP POST request */
  post(url, data = {}): Observable<any> {
    return this.http.post(this.generateFullUrl(url), data);
  }

  /** Send HTTP PUT request */
  put(url, data): Observable<any> {
    return this.http.put(this.generateFullUrl(url), data);
  }

  /** Send HTTP PATCH request */
  patch(url, data): Observable<any> {
    return this.http.patch(this.generateFullUrl(url), data);
  }

  /** Send HTTP DELETE request */
  delete(url): Observable<any> {
    return this.http.delete(this.generateFullUrl(url));
  }
}
