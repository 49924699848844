<audio #audioAdded src="/assets/sound/order-added-sound.mp3"></audio>
<audio #audioReminder src="/assets/sound/order-reminder-sound.mp3"></audio>

<div
    *ngIf="!deviceService.isMobile()"
    class="card-container"
    [ngClass]="{ 'no-cards': !orders.length }"
    appHorizontalScroll
    [horizontalExpectEl]="'order-details-item'"
    #ordersContainer
>
    <!--     dragScroll [snap-disabled]="true">-->
    <app-order-card
        *ngFor="let order of orders; let i = index"
        [(order)]="orders[i]"
        class="order-card-{{ orders[i].id }}"
        (orderStatusChange)="getOrdersList($event)"
    >
    </app-order-card>

    <div class="no-cards-title" *ngIf="!orders.length">
        {{ 'ORDERS.NO_ORDER_TITLE' | translate }}
    </div>
</div>

<div
    *ngIf="deviceService.isMobile()"
    class="card-container mobile"
    #ordersContainer
    [ngClass]="{ 'no-cards': !orders.length }"
>
    <app-order-card
        *ngFor="let order of orders; let i = index; let last = last"
        [ngClass]="{ last: last }"
        [(order)]="orders[i]"
        class="order-card-{{ orders[i].id }}"
        (orderStatusChange)="getOrdersList($event)"
    >
    </app-order-card>

    <div class="no-cards-title" *ngIf="!orders.length">
        {{ 'ORDERS.NO_ORDER_TITLE' | translate }}
    </div>
</div>
