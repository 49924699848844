import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-selector-dropdown',
  templateUrl: './selector-dropdown.component.html',
  styleUrls: ['./selector-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectorDropdownComponent implements OnChanges {
  @Input() data: unknown[] = [];
  @Input() placeholderText: string;
  @Input() defaultSelection: string | number;
  @Input() tableLayout = false;
  @Input() clearable = false;
  @Output() selectedChange = new EventEmitter<string | number>();

  selected: string | number;

  ngOnChanges({ defaultSelection: { currentValue } }: SimpleChanges) {
    if (!currentValue) {
      this.selected = null;
    } else if (currentValue.length || currentValue.id) {
      this.selected = currentValue;
    }
  }

  emitValue() {
    this.selectedChange.emit(this.selected);
  }
}
