import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrdersService } from '../../services/orders.service';
import { Subscription } from 'rxjs';
import { DeviceService } from '../../../../services/device.service';
import { ActivatedRoute } from '@angular/router';
import { MainConstants } from '../../../../constant';
import { LoaderService } from '../../../../services/loader.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit, AfterViewInit, OnDestroy {
  orders: any;
  parentEl: any;
  dataSubscription: Subscription;
  getOrdersInterval: any;
  reminderInterval: any;
  @ViewChild('ordersContainer', { static: false }) ordersContainer: ElementRef;
  @ViewChild('audioAdded', { static: false }) audioAddedPlayerRef: ElementRef;
  @ViewChild('audioReminder', { static: false }) audioReminderPlayerRef: ElementRef;

  /** Instantiate AuthService*/
  constructor(
    public deviceService: DeviceService,
    private elRef: ElementRef,
    private ordersService: OrdersService,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.dataSubscription = this.activatedRoute.data.subscribe((response) => {
      this.orders = response.orders;
    });

    this.getOrdersInterval = setInterval(() => {
      this.getOrdersList();
    }, MainConstants.ORDER_LIST_POLL_DELAY);

    this.reminderInterval = setInterval(() => {
      this.playReminderSoundEffect();
    }, MainConstants.ORDER_REMINDER_DELAY);
  }

  playAddedSoundEffect() {
    this.audioAddedPlayerRef.nativeElement.play();
  }

  playReminderSoundEffect() {
    let isThereUnassignedOrder = false;
    let index = 0;
    while (index < this.orders.length && !isThereUnassignedOrder) {
      if (this.orders[index].status === MainConstants.ORDER_STATUS.ORDERED) {
        isThereUnassignedOrder = true;
      }
      index++;
    }

    if (isThereUnassignedOrder) {
      this.audioReminderPlayerRef.nativeElement.load();
      this.audioReminderPlayerRef.nativeElement.play();
    }
  }

  ngAfterViewInit() {
    this.parentEl = this.elRef.nativeElement.parentElement;
    this.parentEl.classList.add('grey-bg');
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
    this.parentEl.classList.remove('grey-bg');
    clearInterval(this.getOrdersInterval);
    clearInterval(this.reminderInterval);
  }

  scrollToActive(activeCardElement) {
    const element = this.ordersContainer.nativeElement;
    if (this.deviceService.isMobile()) {
      element.scrollTo({ top: activeCardElement.top, behavior: 'smooth' });
    } else {
      element.scrollTo({ left: activeCardElement.left, behavior: 'smooth' });
    }
  }

  getOrdersList(activeElementId?) {
    this.loaderService.showInHeader();
    this.ordersService.getOrders().subscribe((response) => {
      if (this.isAddedNewOrder(response)) {
        this.playAddedSoundEffect();
      }
      this.orders = response;

      setTimeout(() => {
        if (!!this.orders.find((x) => x.id === activeElementId)) {
          const activeElement = document.getElementById(`.order-card-${activeElementId}`);
          this.scrollToActive(activeElement);
        }
      });

      this.loaderService.hideInHeader();
    }, () => {
      this.loaderService.hideInHeader();
    });
  }

  isAddedNewOrder(newList) {
    if (newList.length > this.orders.length) {
      let isThereDiff = false;
      let index = 0;
      while (index < newList.length && !isThereDiff) {
        if (!this.orders.find((x) => x.id === newList[index].id)) {
          isThereDiff = true;
        }
        index++;
      }
      return isThereDiff;
    } else {
      return false;
    }
  }
}
