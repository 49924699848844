import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../../../classes/product';
import { ProductsService } from '../../../../services/products.service';
import { Helper } from '../../../../../../helper';
import { ModalService } from '../../../../../../components/modal/modal.service';
import { ItProductsService } from '../../../../services/it-products.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../../../../../toast-message/toast-message.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() isIt: number;
  @Input() product: Product = new Product();
  @Input() products: Product[];
  @Output() productsChange = new EventEmitter<Product[]>();
  editable = false;
  service: ProductsService | ItProductsService;

  // Only matter if type new
  @Input() new = false;
  @Output() addingNewCanceled = new EventEmitter();

  constructor(
    private productsService: ProductsService,
    private itProductsService: ItProductsService,
    private modalService: ModalService,
    private translate: TranslateService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    if (this.isIt) {
      this.service = this.itProductsService;
    } else {
      this.service = this.productsService;
    }
  }

  onEdit() {
    this.editable = true;
  }

  onCancel() {
    if (this.new) {
      this.addingNewCanceled.emit();
    } else {
      this.editable = false;
    }
  }

  getImageUrl(url) {
    return url
      ? {
          'background-image': `url('${url}')`,
        }
      : {};
  }

  showSuccess(message: string): void {
    this.translate.get(message).subscribe((res: string) => {
      this.toastMessageService.showSucccess(res);
    });
  }

  showError(message: string): void {
    this.translate.get(message).subscribe((res: string) => {
      this.toastMessageService.showError(res);
    });
  }

  addProduct(newProduct: Product): void {
    this.service
      .addProduct({
        name_en: newProduct.name,
        position: this.products.length,
        image: newProduct.image,
      })
      .subscribe(
        (addedProduct) => {
          this.products.push(addedProduct);
          this.editable = false;
          this.addingNewCanceled.emit();
          this.showSuccess('MANAGE_SERVICES.PRODUCT_LIST.TOAST_MESSAGES.SUCCESSFULLY.ADDED');
        },
        (err) => {
          if (err.status === 400) {
            this.showError('MANAGE_SERVICES.PRODUCT_LIST.TOAST_MESSAGES.FAILED.EXISTS');
          } else {
            this.showError('MANAGE_SERVICES.PRODUCT_LIST.TOAST_MESSAGES.FAILED.ADDING');
          }
        }
      );
  }

  updateProduct(newProduct: Product, original: Product): void {
    this.product = newProduct;
    const id = original.id;
    const newName = newProduct.name;
    const newImage = newProduct.image;

    /** Only send request if there been any changes. */
    if (newName !== original.name || newImage !== original.image) {
      const data = { name_en: newName, image: newImage };
      this.service.updateProduct(id, data).subscribe(
        (_) => {
          const updatedProduct = Helper.getElmById(this.products, id);
          updatedProduct.name = newName;
          this.editable = false;
          this.showSuccess('MANAGE_SERVICES.PRODUCT_LIST.TOAST_MESSAGES.SUCCESSFULLY.UPDATED');
        },
        (err) => {
          if (err.status === 400) {
            this.showError('MANAGE_SERVICES.PRODUCT_LIST.TOAST_MESSAGES.FAILED.EXISTS');
          } else {
            this.showError('MANAGE_SERVICES.PRODUCT_LIST.TOAST_MESSAGES.FAILED.UPDATING');
          }
        }
      );
    } else {
      this.editable = false;
    }
  }

  deleteProduct(product: Product): void {
    this.modalService
      .open({
        bodyText: 'MANAGE_SERVICES.PRODUCT_LIST.DELETE_PRODUCT_QUESTION',
        bodyParam: product.name,
      })
      .then(() => {
        this.service.deleteProduct(product.id).subscribe(
          () => {
            this.products = Helper.delElmById(this.products, product.id);
            this.showSuccess('MANAGE_SERVICES.PRODUCT_LIST.TOAST_MESSAGES.SUCCESSFULLY.DELETED');
          },
          (error) => {
            this.showError('MANAGE_SERVICES.PRODUCT_LIST.TOAST_MESSAGES.FAILED.DELETING');
          }
        );
      });
  }
}
