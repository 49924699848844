<div class="login-app-container" [ngClass]="{ notMobile: !deviceService.isMobile() }">
    <div class="row">
        <div class="col-auto mx-auto my-2">
            <img class="img-fluid" src="/assets/images/app_icon.svg" />
        </div>
    </div>
    <div class="row">
        <div class="col-auto mx-auto">
            <h2 class="text-uppercase text-center title-app">
                <span class="title-ey">{{ 'APP.TITLE_EY' | translate }}</span>
                {{ 'APP.TITLE_CATERING' | translate }}
                <br />
                {{ 'APP.TITLE_HOST' | translate }}
            </h2>
        </div>
    </div>
    <div class="row">
        <div class="col col-sm-6 mx-auto login-container">
            <form
                *ngIf="!isConfirmationForm"
                class="my-4"
                [formGroup]="loginForm"
                (ngSubmit)="onLogin()"
            >
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="email"
                            class="form-control email-input"
                            id="username"
                            formControlName="userName"
                            (ngModelChange)="removeErrorIcon()"
                            placeholder="{{ 'LOGIN.USER_NAME_PLACEHOLDER' | translate }}"
                            autofocus
                            required
                            [ngClass]="{ 'show-error': showError }"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="password"
                            class="form-control"
                            id="password"
                            formControlName="password"
                            required
                            (ngModelChange)="removeErrorIcon()"
                            autocomplete="new-password"
                            placeholder="{{ 'LOGIN.PASSWORD_PLACEHOLDER' | translate }}"
                            [ngClass]="{ 'show-error': showError }"
                        />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 mx-auto">
                        <button type="submit" class="login-button" [disabled]="!loginForm.valid">
                            <img class="log-in-icon" src="/assets/images/log_in_icon.svg" />
                            <span>{{ 'LOGIN.LOGIN_BUTTON_TITLE' | translate }}</span>
                        </button>
                    </div>
                </div>

                <div class="form-row confirm-password-link">
                    <div class="col-12 mx-auto">
                        <a [routerLink]="'/' + PAGE_URLS.CONFIRM_REGISTRATION">
                            {{ 'LOGIN.NAVIGATE_TO_CONFIRM' | translate }}</a
                        >
                    </div>
                </div>
            </form>

            <form
                *ngIf="isConfirmationForm"
                class="my-4"
                [formGroup]="registrationForm"
                (ngSubmit)="onLogin()"
            >
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="email"
                            class="form-control email-input"
                            id="confirmUserEmail"
                            formControlName="confirmUserEmail"
                            (ngModelChange)="removeErrorIcon()"
                            placeholder="{{ 'LOGIN.USER_NAME_PLACEHOLDER' | translate }}"
                            autofocus
                            required
                            [ngClass]="{
                                'show-error': showErrorIndicators(
                                    registrationForm.get('confirmUserEmail').invalid,
                                    'email'
                                )
                            }"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="password"
                            class="form-control email-input"
                            id="newPassword"
                            formControlName="newPassword"
                            (ngModelChange)="removeErrorIcon()"
                            placeholder="{{ 'LOGIN.NEW_PASSWORD_PLACEHOLDER' | translate }}"
                            autofocus
                            required
                            [ngClass]="{
                                'show-error': showErrorIndicators(
                                    registrationForm.get('newPassword').invalid,
                                    'password'
                                )
                            }"
                        />
                    </div>
                    <a
                        tabindex="0"
                        class="error-btn"
                        *ngIf="
                            showErrorIndicators(
                                registrationForm.get('newPassword').invalid,
                                'password'
                            )
                        "
                        triggers="click:blur"
                        #passwordT="ngbTooltip"
                        ngbTooltip="{{ 'LOGIN.PASSWORD_REQUIREMENT' | translate }}"
                    >
                        <img class="error-indicator" src="/assets/images/required_info_icon.svg" />
                    </a>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="password"
                            class="form-control"
                            id="newPasswordConfirm"
                            formControlName="passwordConfirm"
                            required
                            (ngModelChange)="removeErrorIcon()"
                            autocomplete="new-password"
                            placeholder="{{
                                'LOGIN.PASSWORD_CONFIRMATION_PLACEHOLDER' | translate
                            }}"
                            [ngClass]="{
                                'show-error': showErrorIndicators(
                                    registrationForm.get('passwordConfirm').invalid,
                                    'password'
                                )
                            }"
                        />
                    </div>
                    <a
                        tabindex="0"
                        class="error-btn"
                        *ngIf="
                            showErrorIndicators(
                                registrationForm.get('passwordConfirm').invalid,
                                'password'
                            )
                        "
                        triggers="click:blur"
                        #passwordConfirmT="ngbTooltip"
                        ngbTooltip="{{ 'LOGIN.PASSWORD_REQUIREMENT' | translate }}"
                    >
                        <img class="error-indicator" src="/assets/images/required_info_icon.svg" />
                    </a>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            id="pinConfirm"
                            formControlName="pinConfirm"
                            required
                            (ngModelChange)="removeErrorIcon()"
                            placeholder="{{ 'LOGIN.PIN_CONFIRMATION_PLACEHOLDER' | translate }}"
                            [ngClass]="{
                                'show-error': showErrorIndicators(
                                    registrationForm.get('pinConfirm').invalid,
                                    'pin'
                                )
                            }"
                        />
                    </div>
                    <a
                        tabindex="0"
                        class="error-btn"
                        *ngIf="
                            showErrorIndicators(registrationForm.get('pinConfirm').invalid, 'pin')
                        "
                        triggers="click:blur"
                        #passwordConfirmT="ngbTooltip"
                        ngbTooltip="{{ 'LOGIN.PIN_REQUIREMENT' | translate }}"
                    >
                        <img class="error-indicator" src="/assets/images/required_info_icon.svg" />
                    </a>
                </div>
                <div class="form-row">
                    <div class="col-12 mx-auto">
                        <button
                            type="submit"
                            class="login-button"
                            [disabled]="!registrationForm.valid"
                        >
                            <img class="log-in-icon" src="/assets/images/log_in_icon.svg" />
                            <span>{{ 'LOGIN.LOGIN_BUTTON_TITLE' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
