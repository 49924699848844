import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './modules/auth/auth.guard';
import { ManageRoomsComponent } from './modules/manage-rooms/manage-rooms.component';
import { MainConstants } from './constant';
import { OrderListComponent } from './modules/orders/components/order-list/order-list.component';
import { UsersComponent } from './modules/users/users.component';
import { ManageServicesComponent } from './modules/manage-services/manage-services.component';
import { UsersResolve } from './modules/users/services/user.resolve';
import { OrdersResolve } from './modules/orders/components/order-list/orders.resolve';
import { ProductCategoriesComponent } from './modules/manage-services/components/product-categories/product-categories.component';
import { SupportCategoriesComponent } from './modules/manage-services/components/support-categories/support-categories.component';
import { ProductsResolve } from './modules/manage-services/resolve/products.resolve';
import { RoomsResolve } from './modules/manage-rooms/rooms.resolve';
import { ItProductsResolve } from './modules/manage-services/resolve/it-products.resolve';
import { ManageProductsComponent } from './modules/manage-services/components/sub-products/manage-products/manage-products.component';
import { ManageSupportListComponent } from './modules/manage-services/components/sub-products/manage-support-list/manage-support-list.component';
import { SubProductsResolve } from './modules/manage-services/resolve/sub-products.resolve';
import { ItSubProductsResolve } from './modules/manage-services/resolve/it-sub-products.resolve';
import { ReportsComponent } from './modules/reports/reports.component';
import { ReportsResolve } from './modules/reports/reports.resolve';

const routes: Routes = [
  {
    path: MainConstants.PAGE_URLS.LOGIN,
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: MainConstants.PAGE_URLS.CONFIRM_REGISTRATION,
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: MainConstants.PAGE_URLS.ORDERS,
    component: OrderListComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      orders: OrdersResolve,
    },
  },
  {
    path: MainConstants.PAGE_URLS.MANAGE_ROOMS,
    component: ManageRoomsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      rooms: RoomsResolve,
    },
  },
  {
    path: MainConstants.PAGE_URLS.USERS,
    component: UsersComponent,
    canActivate: [AuthGuard],
    resolve: {
      users: UsersResolve,
      rooms: RoomsResolve,
    },
  },
  {
    path: MainConstants.PAGE_URLS.REPORTS,
    component: ReportsComponent,
    canActivate: [AuthGuard],
    resolve: {
      reportsData: ReportsResolve,
    },
  },
  {
    path: MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SERVICES,
    component: ManageServicesComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: MainConstants.PAGE_URLS.PRODUCTS.MANAGE_PRODUCT_CATEGORIES,
    component: ProductCategoriesComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      products: ProductsResolve,
    },
  },
  {
    path: MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SUPPORT_CATEGORIES,
    component: SupportCategoriesComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      products: ItProductsResolve,
    },
  },
  {
    path: MainConstants.PAGE_URLS.PRODUCTS.MANAGE_PRODUCT_CATEGORIES + '/:productId',
    component: ManageProductsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      subProducts: SubProductsResolve,
    },
  },
  {
    path: MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SUPPORT_CATEGORIES + '/:productId',
    component: ManageSupportListComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      subProducts: ItSubProductsResolve,
    },
  },
  {
    path: '**',
    redirectTo: MainConstants.PAGE_URLS.LOGIN,
  },
];

/** Main routing module*/
export const AppRoutingModule = RouterModule.forRoot(routes, {});
