import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
})
export class AddButtonComponent {
  @Input() text = '';
  @Input() clicked = false;
  @Output() clickedChange = new EventEmitter<boolean>();
  @Output() activated = new EventEmitter();

  constructor() {}

  onClick(event) {
    this.clicked = !this.clicked;
    this.clickedChange.emit(this.clicked);
    if (this.clicked) {
      this.activated.emit();
    }
  }
}
