import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appHorizontalScroll]',
})
export class HorizontalScrollDirective implements OnInit {
  nativeElement: any;
  @Input() horizontalExpectEl = null;

  constructor(private element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  scrollHorizontally(event) {
    event = window.event || event;

    if (
      !this.horizontalExpectEl ||
      (event.target && !event.target.classList.contains(this.horizontalExpectEl))
    ) {
      const delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail));
      this.nativeElement.scrollLeft -= delta * 60; // Multiplied by 40
      event.preventDefault();
    }
  }

  ngOnInit() {
    if (this.nativeElement.addEventListener) {
      // IE9, Chrome, Safari, Opera
      this.nativeElement.addEventListener(
        'mousewheel',
        (event) => {
          this.scrollHorizontally(event);
        },
        { passive: false }
      );
      // Firefox
      this.nativeElement.addEventListener(
        'DOMMouseScroll',
        (event) => {
          this.scrollHorizontally(event);
        },
        { passive: false }
      );
    } else {
      // IE 6/7/8
      this.nativeElement.attachEvent('onmousewheel', (event) => {
        this.scrollHorizontally(event);
      });
    }
  }
}
