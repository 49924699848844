import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../modules/auth/auth.service';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {MainConstants} from '../../constant';
import {User} from '../../classes/user';

import {LoaderService, LoaderState} from '../../services/loader.service';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  PAGE_URLS = MainConstants.PAGE_URLS;
  pageNames = {};
  pageName: string;
  loaderServiceSubscription: Subscription;
  public showGlobalLoaderInHeader: boolean;
  showBackArrow = false;
  navigateBackTo: string;

  constructor(
    public authService: AuthService,
    private location: Location,
    private router: Router,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.pageNames['/' + MainConstants.PAGE_URLS.ORDERS] = 'PAGE_NAMES.ORDERS';
    this.pageNames['/' + MainConstants.PAGE_URLS.ORDERS + '/:param'] = 'PAGE_NAMES.ORDER_DETAILS';
    this.pageNames['/' + MainConstants.PAGE_URLS.MANAGE_ROOMS] = 'PAGE_NAMES.MANAGE_ROOMS';
    this.pageNames['/' + MainConstants.PAGE_URLS.USERS] = 'PAGE_NAMES.USERS';
    this.pageNames['/' + MainConstants.PAGE_URLS.REPORTS] = 'PAGE_NAMES.REPORTS';
    this.pageNames['/' + MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SERVICES] =
      'PAGE_NAMES.MANAGE_SERVICES';
    this.pageNames['/' + MainConstants.PAGE_URLS.PRODUCTS.MANAGE_PRODUCT_CATEGORIES] =
      'PAGE_NAMES.MANAGE_PRODUCT_CATEGORIES';
    this.pageNames['/' + MainConstants.PAGE_URLS.PRODUCTS.MANAGE_PRODUCT_CATEGORIES + '/:param'] =
      'PAGE_NAMES.MANAGE_PRODUCTS';
    this.pageNames['/' + MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SUPPORT_CATEGORIES] =
      'PAGE_NAMES.MANAGE_SUPPORT_CATEGORIES';
    this.pageNames['/' + MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SUPPORT_CATEGORIES + '/:param'] =
      'PAGE_NAMES.MANAGE_SUPPORT_LIST';

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((route: NavigationEnd) => {
        const replacedUrl = route.url.replace(new RegExp('[0-9]+', 'g'), ':param');

        this.pageName = this.pageNames[replacedUrl];

        this.initBackArrow();
      });

    this.loaderServiceSubscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.showGlobalLoaderInHeader = !!state.showInHeader;
      }
    );
  }

  ngOnDestroy() {
    this.loaderServiceSubscription.unsubscribe();
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  logout() {
    this.authService.logout();
    this.router.navigate([MainConstants.PAGE_URLS.LOGIN]);
  }

  initBackArrow() {
    switch (this.pageName) {
      case this.pageNames['/' + MainConstants.PAGE_URLS.PRODUCTS.MANAGE_PRODUCT_CATEGORIES]:
        this.showBackArrow = true;
        this.navigateBackTo = MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SERVICES;
        break;
      case this.pageNames['/' + MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SUPPORT_CATEGORIES]:
        this.showBackArrow = true;
        this.navigateBackTo = MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SERVICES;
        break;
      case this.pageNames[
        '/' + MainConstants.PAGE_URLS.PRODUCTS.MANAGE_PRODUCT_CATEGORIES + '/:param'
      ]:
        this.showBackArrow = true;
        this.navigateBackTo = MainConstants.PAGE_URLS.PRODUCTS.MANAGE_PRODUCT_CATEGORIES;
        break;
      case this.pageNames[
        '/' + MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SUPPORT_CATEGORIES + '/:param'
      ]:
        this.showBackArrow = true;
        this.navigateBackTo = MainConstants.PAGE_URLS.PRODUCTS.MANAGE_SUPPORT_CATEGORIES;
        break;
      default:
        this.showBackArrow = false;
    }
  }

  navigateBack() {
    this.router.navigate([this.navigateBackTo]);
  }
}
