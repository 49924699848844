import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Room } from '../../classes/room';
import { Helper } from '../../../../helper';
import { RoomsService } from '../../services/rooms.service';
import { ModalService } from '../../../../components/modal/modal.service';
import { ToastMessageService } from '../../../toast-message/toast-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-room-card',
  templateUrl: './room-card.component.html',
  styleUrls: ['./room-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomCardComponent {
  @Input() new = false;
  @Input() room: Room = new Room();
  @Input() rooms: Room[];
  editable = false;
  @Input() sortableIndex: number;

  // Only count if type new
  @Output() roomsChange = new EventEmitter<Room[]>();
  @Output() addingNewCanceled = new EventEmitter();

  constructor(
    private roomsService: RoomsService,
    private modalService: ModalService,
    private toastMessageService: ToastMessageService,
    private translate: TranslateService
  ) {}

  onEdit() {
    this.editable = true;
  }

  onCancel() {
    if (this.new) {
      this.addingNewCanceled.emit();
    } else {
      this.editable = false;
    }
  }

  showSuccess(message: string): void {
    this.translate.get(message).subscribe((res: string) => {
      this.toastMessageService.showSucccess(res);
    });
  }

  showError(message: string): void {
    this.translate.get(message).subscribe((res: string) => {
      this.toastMessageService.showError(res);
    });
  }

  addRoom(newRoom: Room): void {
    this.roomsService
      .addRoom({
        name_en: newRoom.name,
        description_en: newRoom.description,
        position: this.rooms.length,
      })
      .subscribe(
        (addedRoom) => {
          this.rooms.push(addedRoom);
          this.addingNewCanceled.emit();
          this.showSuccess('MANAGE_ROOMS.ROOM_LIST.TOAST_MESSAGES.SUCCESSFULLY.ADDED');
        },
        (err) => {
          if (err.status === 400) {
            this.showError('MANAGE_ROOMS.ROOM_LIST.TOAST_MESSAGES.FAILED.EXISTS');
          } else {
            this.showError('MANAGE_ROOMS.ROOM_LIST.TOAST_MESSAGES.FAILED.ADDING');
          }
        }
      );
  }

  updateRoom(newRoom: Room, original: Room): void {
    const id = original.id;
    const name = newRoom.name;
    const description = newRoom.description;

    const name_changed = name !== original.name;
    const desc_changed = description !== original.description;

    /** Only send request if there have been any changes. */
    if (name_changed || desc_changed) {
      const data = Object.create(null);
      if (name_changed) {
        data.name_en = name;
      }
      if (desc_changed) {
        data.description_en = description;
      }

      this.roomsService.updateRoom(id, data).subscribe(
        () => {
          const updatedRoom = Helper.getElmById(this.rooms, id);
          updatedRoom.name = name;
          updatedRoom.description = description;
          this.editable = false;
          this.showSuccess('MANAGE_ROOMS.ROOM_LIST.TOAST_MESSAGES.SUCCESSFULLY.UPDATED');
        },
        (err) => {
          if (err.status === 400) {
            this.showError('MANAGE_ROOMS.ROOM_LIST.TOAST_MESSAGES.FAILED.EXISTS');
          } else {
            this.showError('MANAGE_ROOMS.ROOM_LIST.TOAST_MESSAGES.FAILED.UPDATING');
          }
        }
      );
    } else {
      this.editable = false;
    }
  }

  deleteRoom(room: Room): void {
    const id = room.id;
    this.modalService
      .open({
        bodyText: 'MANAGE_ROOMS.ROOM_LIST.DELETE_ROOM_QUESTION',
        bodyParam: room.name,
      })
      .then(() => {
        this.roomsService.deleteRoom(id).subscribe(
          () => {
            Helper.delElmById(this.rooms, id);
            this.showSuccess('MANAGE_ROOMS.ROOM_LIST.TOAST_MESSAGES.SUCCESSFULLY.DELETED');
          },
          (error) => {
            this.showError('MANAGE_ROOMS.ROOM_LIST.TOAST_MESSAGES.FAILED.DELETING');
          }
        );
      });
  }
}
