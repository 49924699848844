import { Component, EventEmitter, Output } from '@angular/core';
import { Order } from '../../classes/order';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../services/orders.service';
import { MainConstants } from '../../../../constant';
import { AuthService } from '../../../auth/auth.service';
import { DeviceService } from '../../../../services/device.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../../../toast-message/toast-message.service';

@Component({
  selector: 'app-order-common',
  template: '<ng-content></ng-content>',
})
export class OrderCommonComponent {
  order: Order;
  @Output() orderStatusChange = new EventEmitter<number>();

  constructor(
    public deviceService: DeviceService,
    protected ordersService: OrdersService,
    protected route: ActivatedRoute,
    private authService: AuthService,
    protected router: Router,
    private translate: TranslateService,
    private toastMessageService: ToastMessageService
  ) {}

  claimOrder(event: any) {
    if (event) {
      event.stopPropagation();
    }

    this.changeStatus(MainConstants.ORDER_STATUS.MADE);
  }

  deliverOrder(event: any) {
    if (event) {
      event.stopPropagation();
    }

    this.changeStatus(MainConstants.ORDER_STATUS.DELIVERED);
  }

  private changeStatus(status) {
    this.ordersService.changeStatus(this.order.id, status).subscribe((order) => {
      this.order.status = order.status;
      if (!this.order.user_claimed_by) {
        this.order.user_claimed_by = this.authService.getUser();
      }

      this.orderStatusChange.emit(this.order.id);
      const toastMessage =
        status === MainConstants.ORDER_STATUS.DELIVERED
          ? 'READY_SUCCESS_MESSAGE'
          : 'CLAIM_SUCCESS_MESSAGE';
      this.translate.get('ORDERS.' + toastMessage).subscribe((res: string) => {
        this.toastMessageService.showSucccess(res);
      });
    });
  }

  get orderStatusOrdered() {
    return this.order.status === MainConstants.ORDER_STATUS.ORDERED;
  }

  get orderStatusMade() {
    return this.order.status === MainConstants.ORDER_STATUS.MADE;
  }

  get userClaimedName() {
    let name;
    if (this.order.user_claimed_by.id === this.authService.getUser().id) {
      name = 'Me';
    } else {
      name = this.order.user_claimed_by.name;
    }

    return name;
  }

  get orderStatusDelivered() {
    return this.order.status === MainConstants.ORDER_STATUS.DELIVERED;
  }
}
