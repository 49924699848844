import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Order } from '../../../classes/order';
import { MainConstants } from '../../../../../constant';
import { OrderCommonComponent } from '../../order-common/order-common.component';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderCardComponent extends OrderCommonComponent implements AfterViewInit, OnDestroy {
  @Input() order: Order = new Order();
  @Output() orderChange = new EventEmitter<Order>();
  @ViewChild('orderCardInner', { static: false }) orderCardInner: ElementRef;

  panned = false;
  touchCard = false;

  ngAfterViewInit() {
    /** prevent touch event until pan */
    // this.addEvents(['touchstart', 'touchmove', 'touchend', 'touchcancel']);
    /** prevent click event until pan */
    // this.addEvents(['mousedown', 'mousemove', 'mouseup', 'mouseleave']);
  }

  ngOnDestroy() {
    // this.offEvent('touchstart');
    // this.offEvent('touchmove');
    // this.offEvent('touchend');
    // this.offEvent('touchcancel');
    // this.offEvent('mousedown');
    // this.offEvent('mousemove');
    // this.offEvent('mouseup');
    // this.offEvent('mouseleave');
  }

  setProductAvailable(product) {
    product.available = product.available === null ? false : !product.available;
    this.orderChange.emit(this.order);
  }

  // offEvent(eventName) {
  //   $(this.orderCardInner.nativeElement).off(eventName);
  // }

  // addEvents(events) {
  //   $(this.orderCardInner.nativeElement).on(events[0], () => {
  //     this.touchCard = true;
  //   });
  //
  //   $(this.orderCardInner.nativeElement).on(events[1], () => {
  //     if (this.touchCard) {
  //       this.panned = true;
  //     }
  //   });
  //
  //   $(this.orderCardInner.nativeElement).on(events[2], () => {
  //     setTimeout(() => {
  //       this.panned = false;
  //       this.touchCard = false;
  //     });
  //   });
  //
  //   $(this.orderCardInner.nativeElement).on(events[3], () => {
  //     setTimeout(() => {
  //       this.panned = false;
  //       this.touchCard = false;
  //     });
  //   });
  // }

  get showOutOfStockSign() {
    return this.order.status === MainConstants.ORDER_STATUS.MADE;
  }

  showOutOfStockCheckedSign(orderProduct) {
    return this.showOutOfStockSign && orderProduct.available === false;
  }

  showOutOfStockUnCheckedSign(orderProduct) {
    return this.showOutOfStockSign && orderProduct.available !== false;
  }
}
