import { Injectable } from '@angular/core';
import { AuthService } from './modules/auth/auth.service';

@Injectable()
export class AppConfig {
  constructor(private authService: AuthService) {}

  public load(): Promise<boolean> {
    return this.authService.checkAuth();
  }
}
