import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  CellValueChangedEvent,
  GridOptions,
  ICellEditorParams,
  RowDataTransaction,
} from 'ag-grid-community';
import { User } from '../../classes/user';
import { UsersService } from './services/users.service';
import { AgStatusUserCellRendererComponent } from './components/ag-status-user-cell-renderer/ag-status-user-cell-renderer.component';
import { AgNgSelectCellEditorComponent } from './components/ag-ng-select-cell-editor/ag-ng-select-cell-editor.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainConstants } from '../../constant';
import { ModalService } from '../../components/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { AuthService } from '../auth/auth.service';
import { Role, RoleService } from './services/role.service';
import { Room } from '../manage-rooms/classes/room';
import { DeviceService } from '../../services/device.service';
import { AgPinCellEditorComponent } from './components/ag-pin-cell-editor/ag-pin-cell-editor.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UsersComponent implements OnInit, OnDestroy {
  gridOptions: GridOptions;
  showLoader = true;
  showAddUserForm = false;
  newUser: User = new User();
  filterValue: string;
  dataSubscription: Subscription;
  updateUserTimeout: number;
  errors: any = {};
  roles: Role[] = [];
  rooms: Room[] = [];

  constructor(
    private userService: UsersService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private translate: TranslateService,
    private toastMessageService: ToastMessageService,
    public roleService: RoleService,
    public deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.roles = [...this.roleService.getRoles()];
    this.dataSubscription = this.activatedRoute.data.subscribe((response) => {
      this.rooms = [...response.rooms];
      this.gridOptions = {
        overlayNoRowsTemplate: '<span class="overlay-no-rows">Nothing toDate display</span>',
        cacheQuickFilter: true,
        rowHeight: 40,
        headerHeight: 40,
        rowData: response.users,
        rowSelection: 'single',
        // stopEditingWhenCellsLoseFocus: true,
        onGridReady: () => {
          if (this.gridOptions.api) {
            this.gridOptions.api.sizeColumnsToFit();
          }
        },
        onGridSizeChanged: () => {
          if (this.gridOptions.api) {
            this.gridOptions.api.sizeColumnsToFit();
          }
        },
        getRowId: (data) => {
          return data.data.id;
        },
        onCellClicked: (params: any) => {
          if (params.event.target.className === 'delete-user-icon') {
            this.removeUser(params.data.id);
          } else if (params.event.target.className === 'user-reset-password-cell') {
            this.resetPassword(params.data);
          }
        },
        onCellValueChanged: (event: CellValueChangedEvent) => {
          if (this.updateUserTimeout) {
            clearTimeout(this.updateUserTimeout);
          }

          this.updateUserTimeout = setTimeout(() => {
            this.editUser(event.data);
          }, MainConstants.INLINE_EDIT_TIMEOUT);
        },
        columnDefs: [
          {
            headerName: 'Name',
            field: 'name',
            cellClass: 'justify-content-center user-table-name',
            editable: true,
          },
          {
            headerName: 'email',
            editable: true,
            width: 300,
            // suppressSizeToFit: true,
            field: 'email',
            cellClass: 'justify-content-center',
            cellClassRules: {
              error: (params) => {
                return params.data.id === this.errors.email;
              },
            },
          },
          {
            headerName: 'permission level',
            field: 'role',
            width: 150,
            // suppressSizeToFit: true,
            cellClassRules: {
              'justify-content-center': (params) => {
                return !!params.data;
              },
              disable: (params) => {
                return params.data.id === this.authService.loggedInUser.id;
              },
            },
            editable: (params) => {
              return params.data.id !== this.authService.loggedInUser.id;
            },
            cellRenderer: (params: ICellEditorParams) => {
              return params.value?.name ? params.value?.name : params.value;
            },
            cellEditor: AgNgSelectCellEditorComponent,
            cellEditorParams: {
              dropdownData: this.roles,
              placeholderText: 'USERS.ROLES',
              bindValue: 'name',
            },
          },
          {
            headerName: 'default room',
            field: 'room',
            width: 150,
            cellClass: 'justify-content-center',
            editable: (params) => params.data.room,
            cellRenderer: (params: ICellEditorParams) => {
              if (!params.data.room) {
                return '-';
              }
              return params.value?.name ? params.value?.name : params.value;
            },
            cellEditor: AgNgSelectCellEditorComponent,
            cellEditorParams: {
              dropdownData: response.rooms,
              placeholderText: 'USERS.ROOM',
              bindValue: 'id',
              // if you need to add clear button to table room dropdown
              // clearable: true,
            },
          },
          {
            headerName: 'pin',
            field: 'email_token',
            width: 100,
            cellClass: 'justify-content-center',
            editable: (params) => params.data.room,
            cellEditor: AgPinCellEditorComponent,
            cellRenderer: (params: ICellEditorParams) => {
              if (!params.data.room) {
                return '-';
              }
              return '****';
            },
          },
          {
            headerName: 'password reset',
            cellClass: 'justify-content-center',
            field: 'role',
            width: 150,
            // suppressSizeToFit: true,
            cellRenderer: (params: ICellEditorParams) => {
              return params.value !== 'customer'
                ? `<div class="user-reset-password-cell" title="reset user password">
                        <img alt="reset" src="/assets/images/mail_icon.svg"> Reset
                     </div>`
                : '';
            },
            filter: false,
            sortable: false,
          },
          {
            headerName: 'status',
            field: 'status',
            cellClass: 'justify-content-start',
            width: 110,
            resizable: false,
            suppressSizeToFit: true,
            cellRenderer: AgStatusUserCellRendererComponent,
            getQuickFilterText: function (params) {
              return params.value ? 'active' : 'Inactive';
            },
          },
          {
            headerName: 'delete user',
            field: 'id',
            cellClass: 'justify-content-start',
            width: 100,
            cellRenderer: () => {
              return (
                '<div class="user-delete-cell">' +
                '<img class="delete-user-icon" alt="delete" title="remove user" src="/assets/images/user_delete_icon.svg">' +
                '</div>'
              );
            },
            filter: false,
            sortable: false,
          },
        ],
      };
      this.showLoader = false;
    });
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }

  setUsersFilter(filterValue: string) {
    this.gridOptions.api.setQuickFilter(filterValue);
  }

  showUserForm() {
    this.showAddUserForm = true;
    this.newUser = new User();
  }

  cancelUserForm() {
    this.newUser = new User();
    this.showAddUserForm = false;
    this.errors = {};
  }

  saveUserForm() {
    this.removeEmptyProps(this.newUser);

    this.userService.createUser(this.newUser).subscribe(
      (newUser) => {
        const transaction: RowDataTransaction = {
          add: [newUser],
        };
        this.gridOptions.api.applyTransaction(transaction);
        this.userService.users.push(Object.assign({}, newUser));
        this.cancelUserForm();
        this.translate.get('USERS.SAVE_SUCCESS_MESSAGE').subscribe((res: string) => {
          this.toastMessageService.showSucccess(res);
        });
      },
      (data) => {
        if (data.error.error.meta.email) {
          const emailError =
            data.error.error.meta.email[0] === 'The email domain is disallowed.'
              ? 'USERS.ERROR.DISALLOWED_EMAIL_DOMAIN'
              : 'USERS.ERROR.UNIQUE_EMAIL_REQUIRED';

          this.translate.get(emailError).subscribe((res: string) => {
            this.errors.email = res;
            this.toastMessageService.showError(res);
          });
        }
      }
    );
  }

  private removeEmptyProps(user: User) {
    Object.keys(user).forEach(key => {
      if (!user[key]) {
        delete user[key];
      }
    });
  }

  editUser(user) {
    const newUserData = {
      name: user.name,
      role: user.role?.name ? user.role?.name : user.role,
      room_id: user.room?.id ? user.room?.id : user.room,
      is_active: user.is_active,
      email_token: user.email_token,
    };
    const oldUserDataIndex = this.userService.users.findIndex((x) => x.id === user.id);

    if (this.userService.users[oldUserDataIndex].email !== user.email) {
      newUserData['email'] = user.email;
    }

    this.userService.updateUser(user.id, newUserData).subscribe(
      (data) => {
        this.userService.users[oldUserDataIndex].email = data['email'];
        this.errors = {};
        this.gridOptions.api.refreshCells({ force: true });

        this.translate.get('USERS.SAVE_SUCCESS_MESSAGE').subscribe((res: string) => {
          this.toastMessageService.showSucccess(res);
        });
      },
      (data) => {
        if (data.error.error.meta.email) {
          const emailError =
            data.error.error.meta.email[0] === 'The email domain is disallowed.'
              ? 'USERS.ERROR.DISALLOWED_EMAIL_DOMAIN'
              : 'USERS.ERROR.UNIQUE_EMAIL_REQUIRED';
          this.translate.get(emailError).subscribe((res: string) => {
            this.errors.email = user.id;
            this.gridOptions.api.refreshCells({ force: true });

            this.toastMessageService.showError(res);
          });
        }
      }
    );
  }

  resetPassword(user: User) {
    this.modalService
      .open({
        bodyText: 'USERS.PASSWORD_RESET_CONFIRMATION_MESSAGE',
        bodyParam: user.name,
      })
      .then(() => {
        this.userService.resetPassword(user.id).subscribe(() => {
          this.translate
            .get('USERS.RESET_PASSWORD_SUCCESS_MESSAGE')
            .subscribe((response: string) => {
              this.toastMessageService.showSucccess(response);
            });
        });
      });
  }

  removeUser(userID: number) {
    this.modalService.open({ bodyText: 'USERS.DELETE_CONFIRMATION_MESSAGE' }).then(() => {
      this.userService.deleteUser(userID).subscribe(() => {
        const transaction: RowDataTransaction = {
          remove: [{ id: userID }],
        };
        this.gridOptions.api.applyTransaction(transaction);

        this.translate.get('USERS.REMOVE_USER_SUCCESS_MESSAGE').subscribe((res: string) => {
          this.toastMessageService.showSucccess(res);
        });
      });
    });
  }

  setRole(role: Role) {
    this.newUser.role = role.name;
  }

  setRoom(room: Room | null) {
    this.newUser.room = room?.id ?? null;
  }
}
