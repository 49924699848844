<form class="form-inline">
    <div class="form-group {{ bgColor }}">
        <div class="input-group">
            <input
                class="form-control"
                placeholder="{{ dateFormat }}"
                readonly
                name="dp"
                [(ngModel)]="selectedDate"
                (ngModelChange)="dateSelect()"
                ngbDatepicker
                aria-describedby="datepicker-button-addon"
                [minDate]="minDate"
                [maxDate]="maxDate"
                #d="ngbDatepicker"
            />
            <button
                class="btn btn-outline-secondary calendar-btn"
                (click)="d.toggle()"
                type="button"
                id="datepicker-button-addon"
            >
                <img src="/assets/images/icon_calendar.svg" />
            </button>
        </div>
    </div>
</form>
