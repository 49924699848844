export class User {
  constructor(
    public id: number = 0,
    public email: string = '',
    public name: string = '',
    public role: string = '',
    public room: number = 0,
    public is_active: boolean = true,
    public email_token: string | null = null
  ) {}
}
