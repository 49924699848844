import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService, LoaderState} from './services/loader.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ToastMessageModel} from './modules/toast-message/toast-message.model';
import {MainConstants} from './constant';
import {ToastMessageService} from './modules/toast-message/toast-message.service';
import {FirebaseMessagingService} from './services/firebase-messaging.service';

/** Main component*/
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /** subscription to loader state change*/
  private loaderServiceSubscription: Subscription;
  /** Indicate loader is shown*/
  public showGlobalLoader: boolean;
  /** Store messages, that may comes from HTTP request*/
  public messageList: ToastMessageModel[] = [];

  /** set translate languages, instantiates other services*/
  constructor(
    private translate: TranslateService,
    private loaderService: LoaderService,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private messagingService: FirebaseMessagingService
  ) {
    this.messagingService.receiveMessage();
    translate.addLangs(['en', 'hu']);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }

  /**
   * Subscribe to loaderState change
   * */
  ngOnInit() {
    this.loaderServiceSubscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.showGlobalLoader = !!state.show;
      }
    );

    this.toastMessageService.getAlertEvent().subscribe((r) => {
      this.messageList.push(r);
      setTimeout(() => {
        for (let i = 0; i < this.messageList.length; i++) {
          const obj = this.messageList[i];
          if (obj === r) {
            this.messageList.splice(i, 1);
            break;
          }
        }
      }, MainConstants.TOAST_TIMEOUT);
    });
  }

  get showHeader() {
    return !this.router.url.includes('login') && !this.router.url.includes('confirm-registration');
  }
}
