import { Component, Input, OnInit } from '@angular/core';
import { ToastMessageModel } from './toast-message.model';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
})
export class ToastMessageComponent implements OnInit {
  constructor() {}

  @Input() alerts: ToastMessageModel[] = [];

  ngOnInit() {}

  closeAlert(alert: any) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
