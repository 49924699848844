export const environment = {
  production: true,
  apiUrl: 'https://api.ey-catering-testing.attrecto-dev.com/api/en',
  firebase: {
    apiKey: 'AIzaSyBZ-qYw2zxOCv8vlaFrCJHglp-gE9lTcI0',
    authDomain: 'ey-catering-testing.firebaseapp.com',
    projectId: 'ey-catering-testing',
    storageBucket: 'ey-catering-testing.appspot.com',
    messagingSenderId: '533509170843',
    appId: '1:533509170843:web:3ec71ccfedc41b3923c83e',
  },
};
