import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Room } from './classes/room';
import { RoomsService } from './services/rooms.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../toast-message/toast-message.service';

@Component({
  selector: 'app-manage-rooms',
  templateUrl: './manage-rooms.component.html',
  styleUrls: ['./manage-rooms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageRoomsComponent implements OnInit {
  rooms: Room[];
  showNew = false;
  @ViewChild('roomsContainer', { static: false }) roomsContainer: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private roomsService: RoomsService,
    private translate: TranslateService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.getRooms();
  }

  getRooms(): void {
    this.activatedRoute.data.subscribe((response) => {
      this.rooms = response.rooms;
    });
  }

  onAddingNewCanceled() {
    this.showNew = false;
  }

  scrollToEnd() {
    const element = this.roomsContainer.nativeElement;
    element.scrollTo({ left: element.scrollWidth, behavior: 'smooth' });
  }

  sorted() {
    const data = this.rooms.map((room, index) => {
      return {
        id: room.id,
        name: room.name,
        position: index,
      };
    });

    this.roomsService.sortRooms(data).subscribe(
      (response) => {},
      (error) => {
        this.translate
          .get('MANAGE_ROOMS.ROOM_LIST.TOAST_MESSAGES.FAILED.ORDERING')
          .subscribe((res) => {
            this.toastMessageService.showError(res);
          });
      }
    );
  }
}
