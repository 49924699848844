import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SubProduct } from '../../../classes/subProduct';
import { ItSubProductsService } from '../../../services/it-sub-products.service';
import { SubProductsService } from '../../../services/sub-products.service';
import { ToastMessageService } from '../../../../toast-message/toast-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sub-product-list',
  templateUrl: './sub-product-list.component.html',
  styleUrls: ['./sub-product-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubProductListComponent implements OnInit {
  @Input() isIt = false;
  @Input() subProducts: SubProduct[];
  showNew = false;
  service: SubProductsService | ItSubProductsService;

  constructor(
    private itSubProductsService: ItSubProductsService,
    private subProductsService: SubProductsService,
    private translate: TranslateService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.service = this.isIt ? this.itSubProductsService : this.subProductsService;
  }

  @ViewChild('subProductsListContainer', { static: false }) subProductsListContainer: ElementRef;

  onAddingNewCanceled(): void {
    this.showNew = false;
  }

  scrollToEnd(): void {
    const element = this.subProductsListContainer.nativeElement;
    element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
  }

  onDrop() {
    const data = this.subProducts.map((subProduct, index) => {
      return {
        id: subProduct.id,
        position: index,
      };
    });
    this.service.sortSubProducts(data).subscribe(
      (response) => {},
      (error) => {
        this.translate
          .get('MANAGE_SERVICES.SUB_PRODUCT_LIST.TOAST_MESSAGES.FAILED.ORDERING')
          .subscribe((res) => {
            this.toastMessageService.showError(res);
          });
      }
    );
  }
}
