import { Injectable } from '@angular/core';
import { AuthService } from '../modules/auth/auth.service';
import { LoaderService } from './loader.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import firebase from 'firebase/compat/app';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  serviceWorkerUrl = '/firebase-messaging-sw.js';

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private httpService: HttpService,
    private loaderService: LoaderService
  ) {}

  requestPermission() {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.register(this.serviceWorkerUrl).then((registration) => {
        setTimeout(() => {
          this.getToken(registration);
        });
      });
    } else {
      console.warn('no service worker detected');
    }
  }

  private getToken(registration: ServiceWorkerRegistration) {
    const messaging = firebase.messaging();
    messaging
      .getToken({
        serviceWorkerRegistration: registration,
      })
      .then((token) => {
        this.sendDeviceToken(token);
      });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe({
      next: (payload: firebase.messaging.MessagePayload) => {
        const payloadNotification = payload.notification;

        Notification.bind(
          new Notification(payloadNotification.title, { body: payloadNotification.body })
        );
      },
    });
  }

  sendDeviceToken(token: string) {
    this.sendFirebaseMessagingDeviceToken(token).subscribe();
  }

  sendFirebaseMessagingDeviceToken(token: string) {
    const deviceToken = {
      deviceToken: token,
    };
    console.log(deviceToken);
    return this.httpService.post(`/users/fcm-token`, deviceToken);
  }
}
