<div class="modal-header">
    <img src="/assets/images/warning_icon.svg" />
</div>
<div class="modal-body text-center">
    {{ question }}
</div>
<div class="modal-footer" *ngIf="!hideFooter">
    <button type="button" class="btn modal-button" (click)="activeModal.dismiss()">
        {{ 'MODAL.NO' | translate }}
    </button>
    <button type="button" class="btn modal-button" (click)="activeModal.close()">
        {{ 'MODAL.YES' | translate }}
    </button>
</div>
