import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class DeviceService {
  constructor(private deviceService: DeviceDetectorService) {}

  isMobile() {
    return this.deviceService.isMobile();
  }

  // disable mobile view in admin mode
  isDesktop() {
    return this.deviceService.isDesktop();
  }
}
