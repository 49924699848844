import { Component } from '@angular/core';
import { MainConstants } from '../../constant';

@Component({
  selector: 'app-manage-services',
  templateUrl: './manage-services.component.html',
  styleUrls: ['./manage-services.component.scss'],
})
export class ManageServicesComponent {
  PAGE_URLS = MainConstants.PAGE_URLS;
}
