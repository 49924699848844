import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageServicesComponent } from './manage-services.component';
import { AppRoutingModule } from '../../app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProductCategoriesComponent } from './components/product-categories/product-categories.component';
import { SupportCategoriesComponent } from './components/support-categories/support-categories.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductsService } from './services/products.service';
import { SubProductsService } from './services/sub-products.service';
import { ProductCardComponent } from './components/product-list/components/product-card/product-card.component';
import { ProductFormComponent } from './components/product-list/components/product-form/product-form.component';
import { FormsModule } from '@angular/forms';
import { ProductsResolve } from './resolve/products.resolve';
import { SharedModule } from '../shared/shared.module';
import { ItProductsService } from './services/it-products.service';
import { ItProductsResolve } from './resolve/it-products.resolve';
import { ItSubProductsService } from './services/it-sub-products.service';
import { SubProductListComponent } from './components/sub-products/sub-product-list/sub-product-list.component';
import { ManageProductsComponent } from './components/sub-products/manage-products/manage-products.component';
import { ManageSupportListComponent } from './components/sub-products/manage-support-list/manage-support-list.component';
import { SubProductsResolve } from './resolve/sub-products.resolve';
import { ItSubProductsResolve } from './resolve/it-sub-products.resolve';
import { SubProductCardComponent } from './components/sub-products/sub-product-list/components/sub-product-card/sub-product-card.component';
import { SubProductFormComponent } from './components/sub-products/sub-product-list/components/sub-product-form/sub-product-form.component';

@NgModule({
  declarations: [
    ManageServicesComponent,
    ProductCategoriesComponent,
    SupportCategoriesComponent,
    ProductListComponent,
    ProductCardComponent,
    ProductFormComponent,
    SubProductListComponent,
    ManageProductsComponent,
    ManageSupportListComponent,
    SubProductCardComponent,
    SubProductFormComponent,
  ],
  imports: [AppRoutingModule, FormsModule, CommonModule, TranslateModule, SharedModule],
  exports: [
    ManageServicesComponent,
    ProductCategoriesComponent,
    SupportCategoriesComponent,
    ManageProductsComponent,
    ManageSupportListComponent,
  ],
  providers: [
    ProductsService,
    ItProductsService,
    ProductsResolve,
    ItProductsResolve,
    SubProductsService,
    ItSubProductsService,
    SubProductsResolve,
    ItSubProductsResolve,
  ],
})
export class ManageServicesModule {}
