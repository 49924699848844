import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { OrdersModule } from './modules/orders/orders.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoaderService } from './services/loader.service';
import { HttpModule } from './modules/http/http.module';
import { HttpService } from './services/http.service';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { ManageRoomsModule } from './modules/manage-rooms/manage-rooms.module';
import { AppConfig } from './app.config';
import { DeviceService } from './services/device.service';
import { UsersModule } from './modules/users/users.module';
import { ManageServicesModule } from './modules/manage-services/manage-services.module';
import { JwtModule } from '@auth0/angular-jwt';
import { IsAdminDirective } from './directives/isAdmin';
import { GlobalLoaderComponent } from './components/global-loader/global-loader.component';
import { ToastMessageModule } from './modules/toast-message/toast-message.module';
import { ReportsModule } from './modules/reports/reports.module';
import { NgbCustomDateParserFormatter } from './ngb-date-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

/** get translate files*/
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/* Load user before app loads */
export function initConfig(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function tokenGetter() {
  return localStorage.getItem('auth-token');
}

/** Main module, imports app wide modules, providers, bootstrap*/
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    IsAdminDirective,
    GlobalLoaderComponent,
  ],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    ManageRoomsModule,
    UsersModule,
    AuthModule,
    AppRoutingModule,
    ManageServicesModule,
    SharedModule,
    OrdersModule,
    ReportsModule,
    HttpModule,
    ToastMessageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    LoaderService,
    HttpService,
    DeviceService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: NgbCustomDateParserFormatter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
