import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../modules/auth/auth.service';
import { Router } from '@angular/router';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../services/device.service';
import { MainConstants } from '../../constant';
import { FirebaseMessagingService } from '../../services/firebase-messaging.service';

/**
 * Login component, include form
 * */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  loginForm = new UntypedFormGroup({
    userName: new UntypedFormControl('', Validators.email),
    password: new UntypedFormControl('', Validators.minLength(8)),
  });

  registrationForm = new UntypedFormGroup({
    confirmUserEmail: new UntypedFormControl('', Validators.email),
    newPassword: new UntypedFormControl('', Validators.pattern(MainConstants.PASSWORD_VALIDATOR)),
    passwordConfirm: new UntypedFormControl(
      '',
      Validators.pattern(MainConstants.PASSWORD_VALIDATOR)
    ),
    pinConfirm: new UntypedFormControl('', Validators.minLength(4)),
  });

  showError: boolean;
  showEmailPinError: boolean;
  showPasswordError: boolean;
  PAGE_URLS = MainConstants.PAGE_URLS;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private translate: TranslateService,
    public deviceService: DeviceService,
    private messagingService: FirebaseMessagingService
  ) {}

  ngOnInit() {
    if (this.isConfirmationForm) {
      this.showError = true;
    }
  }

  redirect(data) {
    this.authService.setToken(data.api_token);
    this.router.navigate(['/orders']);

    this.translate.get('LOGIN.LOGIN_SUCCESS_MESSAGE').subscribe((res: string) => {
      this.toastMessageService.showSucccess(res);
    });
  }

  removeErrorIcon() {
    this.showError = false;
    this.showEmailPinError = false;
    this.showPasswordError = false;
  }

  passwordConfirmation() {
    this.authService
      .passwordConfirm(
        this.registrationForm.get('confirmUserEmail').value,
        this.registrationForm.get('newPassword').value,
        this.registrationForm.get('passwordConfirm').value,
        this.registrationForm.get('pinConfirm').value
      )
      .subscribe(
        (data) => {
          this.redirect(data);
        },
        ({ error }) => {
          this.showError = true;
          if (
            error.error.meta &&
            error.error.meta.password &&
            error.error.meta.password[0] === 'validation.confirmed'
          ) {
            this.showPasswordError = true;
            this.showError = false;
            this.translate.get('LOGIN.PASSWORD_MATCH_ERROR_MESSAGE').subscribe((res: string) => {
              this.toastMessageService.showError(res);
            });
          } else if (
            error.error.message === 'Not Found' ||
            (error.error.meta && error.error.meta.email && error.error.meta.email[0])
          ) {
            this.showEmailPinError = true;
            this.showError = false;
            this.translate.get('LOGIN.EMAIL_OR_PIN_WRONG').subscribe((res: string) => {
              this.toastMessageService.showError(res);
            });
          }
        }
      );
  }

  login() {
    this.authService
      .login(this.loginForm.get('userName').value, this.loginForm.get('password').value)
      .subscribe(
        (data) => {
          this.redirect(data);
        },
        (error) => {
          this.showError = true;
          this.translate.get('LOGIN.LOGIN_ERROR_MESSAGE').subscribe((res: string) => {
            this.toastMessageService.showError(res);
          });
        }
      );
  }

  onLogin() {
    if (this.isConfirmationForm) {
      this.passwordConfirmation();
    } else {
      this.login();
    }
  }

  get isConfirmationForm() {
    return this.router.url.includes('confirm-registration');
  }

  showErrorIndicators(invalid, type?) {
    return (
      invalid ||
      this.showError ||
      (this.showEmailPinError && (type === 'email' || type === 'pin')) ||
      (this.showPasswordError && type === 'password')
    );
  }
}
