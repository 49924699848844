import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../../classes/product';

@Component({
  selector: 'app-support-categories',
  templateUrl: './support-categories.component.html',
  styleUrls: ['./support-categories.component.scss'],
})
export class SupportCategoriesComponent implements OnInit {
  products: Product[];

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.getProducts();
  }

  getProducts() {
    this.activatedRoute.data.subscribe((response) => {
      this.products = response.products;
    });
  }
}
