import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MainConstants } from '../../../../constant';

@Component({
  selector: 'app-datepicker-popup',
  templateUrl: './datepicker-popup.component.html',
  styleUrls: ['./datepicker-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatepickerPopupComponent implements OnInit {
  public dateFormat = MainConstants.REPORTS.DATE_FORMAT;

  @Input() bgColor = '';
  @Input('selectedDate') selectedDate;
  @Input('minDate') minDate;
  @Input('maxDate') maxDate;
  @Output() selectedDateChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  dateSelect() {
    this.selectedDateChange.emit(this.selectedDate);
  }
}
