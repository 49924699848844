import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OrdersService } from '../../services/orders.service';
import { Order } from '../../classes/order';

@Injectable()
export class OrdersResolve {
  constructor(private orderService: OrdersService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Order[]> | Promise<Order[]> | Order[] {
    return this.orderService.getOrders();
  }
}
