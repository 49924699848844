import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductsService } from '../../services/products.service';
import { ItProductsService } from '../../services/it-products.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../../../toast-message/toast-message.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductListComponent implements OnInit {
  @Input() isIt = false;
  @Input() products: Product[];
  showNew = false;
  service: ProductsService | ItProductsService;

  @ViewChild('productsContainer', { static: false }) productsContainer: ElementRef;

  constructor(
    private productsService: ProductsService,
    private itProductsService: ItProductsService,
    private translate: TranslateService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    /** Use the proper service */
    this.service = this.isIt ? this.itProductsService : this.productsService;
  }

  onAddingNewCanceled() {
    this.showNew = false;
  }

  scrollToEnd() {
    const element = this.productsContainer.nativeElement;
    element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
  }

  onDrop(): void {
    const data = this.products.map((product, index) => {
      return {
        id: product.id,
        position: index,
      };
    });
    this.service.sortProducts(data).subscribe(
      (response) => {},
      (error) => {
        this.translate
          .get('MANAGE_SERVICES.PRODUCT_LIST.TOAST_MESSAGES.FAILED.ORDERING')
          .subscribe((res) => {
            this.toastMessageService.showError(res);
          });
      }
    );
  }
}
