import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { MainConstants } from '../../../constant';
import { map } from 'rxjs/operators';
import { Order } from '../classes/order';

@Injectable()
export class OrdersService {
  orders: Order[];
  order: Order;

  constructor(private httpService: HttpService) {}

  getOrders() {
    return this.httpService.get(MainConstants.API_END_POINTS.ORDERS).pipe(
      map((data) => {
        return (this.orders = data);
      })
    );
  }

  getOrder(orderId) {
    return this.httpService.get(MainConstants.API_END_POINTS.ORDERS + `/${orderId}`).pipe(
      map((data) => {
        for (const product of data.ordered_products) {
          if (product.available !== null) {
            product.done = !!product.available;
            product.empty = !product.done;
          }
        }
        return (this.order = data);
      })
    );
  }

  setOrderClaim(id) {
    return this.httpService.post(MainConstants.API_END_POINTS.ORDERS + `/${id}/claim`);
  }

  setOrderReady(id, data) {
    return this.httpService.post(MainConstants.API_END_POINTS.ORDERS + `/${id}/ready`, data);
  }

  changeStatus(orderId, status) {
    const order = this.order ? this.order : this.orders.find((x) => x.id === orderId);
    const wsOrder =
      status === MainConstants.ORDER_STATUS.MADE
        ? this.setOrderClaim.bind(this)
        : this.setOrderReady.bind(this);

    return wsOrder(
      orderId,
      this.getReadyAndClaimParams(status !== MainConstants.ORDER_STATUS.MADE, order)
    ).pipe(
      map((response: any) => {
        if (response.success) {
          order.status = status;
        }

        if (status !== MainConstants.ORDER_STATUS.MADE) {
          const selectedOrderIndex = this.orders.findIndex((x) => x.id === order.id);
          this.orders.splice(selectedOrderIndex, 1);
        }

        return order;
      })
    );
  }

  getReadyAndClaimParams(isReady, order) {
    if (isReady) {
      const resultParams = { ordered_products: [] };
      for (const product of order.ordered_products) {
        resultParams.ordered_products.push({
          available: product.available === null ? true : product.available,
          id: product.id,
        });
      }
      return resultParams;
    }
    return {};
  }
}
