import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpResponse,
} from '@angular/common/http';
import { LoaderService } from '../../services/loader.service';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { MainConstants } from '../../constant';
import { Router } from '@angular/router';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class WsInterceptor implements HttpInterceptor {
  constructor(
    private loader: LoaderService,
    private injector: Injector,
    private toastMessageService: ToastMessageService,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiCall = req.urlWithParams.indexOf(environment.apiUrl) > -1;
    const skipNextLoading = this.loader.skipNextLoading;

    if (apiCall && !skipNextLoading) {
      this.loader.show();
    } else {
      this.loader.skipNextLoading = false;
    }

    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse && !skipNextLoading) {
            // handle successful calls
            this.hideLoader(apiCall);
          }
        },
        (err) => {
          this.hideLoader(apiCall);
          if (err instanceof HttpErrorResponse && !skipNextLoading) {
            this.handleError(err, req);
          }
        }
      )
    );
  }

  hideLoader(apiCall) {
    if (apiCall) {
        this.loader.hide();
    }
  }

  handleError(err, req: HttpRequest<any>) {
    const router = this.injector.get(Router);
    const notLoginUrl = req.url.indexOf('admin/login') === -1;

    if (notLoginUrl && err.status === 401) {
      const auth = this.injector.get(AuthService);
      this.authService.logout();
      router.navigate([MainConstants.PAGE_URLS.LOGIN]);
    } else if (err.status === 500) {
      this.translate.get('APP.GLOBAL_ERROR').subscribe((res: string) => {
        this.toastMessageService.showError(res);
      });
    } else {
      console.log('Other Error');
    }
  }
}
