<div [ngClass]="{ 'sub-product-card-container': !isIt, 'it-sub-product-card-container': isIt }">
    <ng-container *ngIf="!new">
        <ng-container *ngIf="editable">
            <app-sub-product-form
                [isIt]="isIt"
                [subProduct]="subProduct"
                (submitted)="updateSubProduct($event, subProduct)"
                (cancel)="onCancel()"
            >
            </app-sub-product-form>
        </ng-container>

        <ng-container *ngIf="!editable">
            <div class="ey-card" [ngStyle]="getImageUrl(subProduct.image)">
                <div class="header d-flex justify-content-between">
                    <button class="edit-icon" role="button" (click)="onEdit()"></button>
                    <!--                    <div class="drag-icon">-->
                    <!-- TODO: add drag and drop -->
                    <!--                    </div>-->
                    <button
                        class="close-icon"
                        role="button"
                        (click)="deleteSubProduct(subProduct)"
                    ></button>
                </div>
                <div class="body">
                    <h4 class="name">
                        {{ subProduct.name }}
                    </h4>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="new">
        <app-sub-product-form
            [isIt]="isIt"
            (submitted)="addSubProduct($event)"
            (cancel)="onCancel()"
        >
        </app-sub-product-form>
    </ng-container>
</div>
