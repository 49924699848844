import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageUploadComponent {
  @Output() onImageUpload = new EventEmitter<any>();

  @ViewChild('imgInput', { static: false }) input: ElementRef;

  openFileManager() {
    this.input.nativeElement.click();
  }

  fileSelected() {
    const file = this.input.nativeElement.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (result) => {
      this.onImageUpload.emit({ file, image: result.target['result'] });
    };
    fileReader.readAsDataURL(file);
  }
}
