import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { Observable } from 'rxjs';
import { SubProduct } from '../classes/subProduct';
import { MainConstants } from '../../../constant';

@Injectable()
export class ItSubProductsService {
  constructor(private httpService: HttpService) {}

  getSubProducts(productId: number): Observable<SubProduct[]> {
    return this.httpService.get(
      MainConstants.API_END_POINTS.IT_PRODUCTS + `/${productId}/sub-products`
    );
  }

  addSubProduct(productId: number, data): Observable<SubProduct> {
    return this.httpService.post(
      MainConstants.API_END_POINTS.IT_PRODUCTS + `/${productId}/sub-products`,
      data
    );
  }

  updateSubProduct(subProductId: number, data): Observable<SubProduct> {
    return this.httpService.patch(
      MainConstants.API_END_POINTS.SUB_PRODUCTS + `/${subProductId}`,
      data
    );
  }

  deleteSubProduct(subProductId: number): Observable<SubProduct> {
    return this.httpService.delete(MainConstants.API_END_POINTS.SUB_PRODUCTS + `/${subProductId}`);
  }

  sortSubProducts(data: { id: number; position: number }[]): Observable<SubProduct[]> {
    return this.httpService.patch(MainConstants.API_END_POINTS.SUB_PRODUCTS_POSITION_UPDATE, {
      positions: data,
    });
  }
}
