<div class="sub-product-header">
    <app-add-button
        [text]="
            isIt
                ? 'MANAGE_SERVICES.SUB_PRODUCT_LIST.MANAGE_SUPPORT_LIST.ADD'
                : 'MANAGE_SERVICES.SUB_PRODUCT_LIST.MANAGE_PRODUCTS.ADD'
        "
        [(clicked)]="showNew"
        (activated)="scrollToEnd()"
    >
    </app-add-button>
</div>

<div class="sub-product-list-container" #subProductsListContainer>
    <div class="sub-product-list" style="cursor: default">
        <!-- TODO: add drag and drop -->
        <div *ngFor="let subProduct of subProducts; let i = index" style="cursor: default">
            <app-sub-product-card
                [subProduct]="subProduct"
                [subProducts]="subProducts"
                [isIt]="isIt"
                style="cursor: default"
            >
            </app-sub-product-card>
        </div>

        <ng-container *ngIf="showNew">
            <app-sub-product-card
                [new]="true"
                [isIt]="isIt"
                [subProducts]="subProducts"
                (addingNewCanceled)="onAddingNewCanceled()"
            >
            </app-sub-product-card>
        </ng-container>
    </div>
</div>
