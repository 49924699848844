<!--<p translate [translateParams]="{value: 'world!'}">-->
<!--HOME.TITLE-->
<!--</p>-->

<!--{{ 'HOME.SELECT' | translate }}-->
<!--<select #langSelect (change)="translate.use(langSelect.value)">-->
<!--<option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>-->
<!--</select>-->

<!--<button (click)="logout()">Logout</button>-->

<div class="header-container">
    <div class="title-bar d-flex justify-content-center align-items-center px-4">
        <div
            class="back-arrow d-flex justify-content-start align-items-center"
            *ngIf="isLoggedIn()"
        >
            <a class="home-link" [routerLink]="PAGE_URLS.ORDERS" *appIsAdmin="true">
                <img src="/assets/images/icon_home.svg" />
            </a>

            <img
                *ngIf="showBackArrow"
                (click)="navigateBack()"
                src="/assets/images/menu/Backward_arrow.svg"
            />
        </div>

        <div class="header-loader" *ngIf="showGlobalLoaderInHeader">
            <app-global-loader [height]="50" [width]="50"></app-global-loader>
        </div>

        <div *ngIf="isLoggedIn()" class="page-name">
            {{ pageName | translate }}
        </div>

        <div *ngIf="isLoggedIn()" class="menu-icons d-flex justify-content-end align-items-center">
            <a
                class=""
                [routerLink]="PAGE_URLS.PRODUCTS.MANAGE_SERVICES"
                routerLinkActive
                #rla="routerLinkActive"
                *appIsAdmin="true"
            >
                <img [hidden]="rla.isActive" src="/assets/images/menu/Products.svg" />
                <img [hidden]="!rla.isActive" src="/assets/images/menu/Products_Selected.svg" />
            </a>
            <a
                class=""
                [routerLink]="PAGE_URLS.MANAGE_ROOMS"
                routerLinkActive
                #rla="routerLinkActive"
                *appIsAdmin="true"
            >
                <img [hidden]="rla.isActive" src="/assets/images/menu/Rooms.svg" />
                <img [hidden]="!rla.isActive" src="/assets/images/menu/Rooms_Selected.svg" />
            </a>
            <a
                class=""
                [routerLink]="PAGE_URLS.USERS"
                routerLinkActive
                #rla="routerLinkActive"
                *appIsAdmin="true"
            >
                <img [hidden]="rla.isActive" src="/assets/images/menu/Users.svg" />
                <img [hidden]="!rla.isActive" src="/assets/images/menu/Users_Selected.svg" />
            </a>
            <a
                class=""
                [routerLink]="PAGE_URLS.REPORTS"
                routerLinkActive
                #rla="routerLinkActive"
                *appIsAdmin="true"
            >
                <img [hidden]="rla.isActive" src="/assets/images/menu/Report.svg" />
                <img [hidden]="!rla.isActive" src="/assets/images/menu/Report_Selected.svg" />
            </a>
            <a class="logout" (click)="logout()">
                <i class="fa fa-2x fa-sign-out"></i>
            </a>
        </div>
    </div>
    <div class="name-bar px-4 d-flex justify-content-end align-items-center">
        <div *ngIf="authService.loggedInUser" class="fw-bold">
            {{ authService.loggedInUser.name }}
        </div>
    </div>
</div>
