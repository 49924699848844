import { Injectable } from '@angular/core';
import { Product } from '../classes/product';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProductsService } from '../services/products.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProductsResolve {
  constructor(private productsService: ProductsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Product[]> | Promise<Product[]> | Product[] {
    return this.productsService.getProducts();
  }
}
