<form class="ey-card active" #form="ngForm" (ngSubmit)="onSubmit($event)">
    <div class="header">
        <button
            *ngIf="form.valid"
            class="approve-icon"
            type="submit"
            role="button"
            [disabled]="!form.valid"
        ></button>
        <button class="close-icon" type="button" role="button" (click)="close()"></button>
    </div>
    <div class="body">
        <input
            type="text"
            class="name-input"
            title="Name"
            autocomplete="off"
            #name
            name="name"
            ngModel
            [ngModel]="room.name"
            required
            appMinTrimLength="1"
            maxlength="25"
            [placeholder]="'MANAGE_ROOMS.ROOM_FORM.NAME_PLACEHOLDER' | translate"
        />
        <input
            type="text"
            class="description-input"
            title="Description"
            autocomplete="off"
            name="description"
            ngModel
            [ngModel]="room.description"
            maxlength="30"
            [placeholder]="'MANAGE_ROOMS.ROOM_FORM.DESCRIPTION_PLACEHOLDER' | translate"
        />
    </div>
</form>
