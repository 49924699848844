const isNumber = (value: any) => {
  return !isNaN(toInteger(value));
};

const toInteger = (value: any) => {
  return parseInt(`${value}`, 10);
};

export class NgbDateConverter {
  static getNgbDate(value) {
    const dateParts = value.trim().split('/');
    if (dateParts.length === 1 && isNumber(dateParts[0])) {
      return { year: toInteger(dateParts[0]), month: null, day: null };
    } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
      return {
        year: toInteger(dateParts[1]),
        month: toInteger(dateParts[0]),
        day: null,
      };
    } else if (
      dateParts.length === 3 &&
      isNumber(dateParts[0]) &&
      isNumber(dateParts[1]) &&
      isNumber(dateParts[2])
    ) {
      return {
        year: toInteger(dateParts[2]),
        month: toInteger(dateParts[1]),
        day: toInteger(dateParts[0]),
      };
    }
  }
}
