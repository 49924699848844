import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { SubProduct } from '../../../../../classes/subProduct';

@Component({
  selector: 'app-sub-product-form',
  templateUrl: './sub-product-form.component.html',
  styleUrls: ['./sub-product-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubProductFormComponent implements AfterViewInit {
  @Input() isIt: number;
  @Input() subProduct: SubProduct = new SubProduct();
  @Output() submitted = new EventEmitter<SubProduct>();
  @Output() cancel = new EventEmitter();

  @ViewChild('name', { static: false }) nameInput: ElementRef;
  @ViewChild('form', { static: false }) form: NgForm;

  public productImageUrl;

  constructor() {}

  ngAfterViewInit() {
    this.nameInput.nativeElement.focus();
  }

  onCancel() {
    this.cancel.emit();
  }

  onImageUpload({ image }) {
    this.productImageUrl = image;
  }

  getImageUrl(url) {
    return url
      ? {
          'background-image': `url('${url}')`,
        }
      : {};
  }

  onSubmit(event) {
    /** Don't allow the user to create new lines, submit the form instead */
    event.preventDefault();

    if (this.form.valid) {
      this.submitted.emit(new SubProduct(0, this.form.form.value.name, null, this.productImageUrl));
    }
  }
}
