import { NgModule } from '@angular/core';
import { OrdersRoutingModule } from './orders-routing.module';
import { OrdersComponent } from './orders.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { SharedModule } from '../shared/shared.module';
import { OrderCardComponent } from './components/order-list/order-card/order-card.component';
import { OrdersService } from './services/orders.service';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { OrderCommonComponent } from './components/order-common/order-common.component';
import { TranslateModule } from '@ngx-translate/core';
import { OrderDetailResolve } from './components/order-details/order-detail.resolve';
import { OrdersResolve } from './components/order-list/orders.resolve';

@NgModule({
  imports: [OrdersRoutingModule, SharedModule, TranslateModule],
  declarations: [
    OrdersComponent,
    NotFoundComponent,
    OrderCardComponent,
    OrderDetailsComponent,
    OrderListComponent,
    OrderCommonComponent,
  ],
  providers: [OrdersService, OrdersResolve, OrderDetailResolve],
})
export class OrdersModule {}
